import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

import { useDispatch, useSelector } from 'react-redux';
import { userLogout, fetchProfileData } from '../../module/User/UserAction';
import { EmployeeData } from '../../utility/EmployeeData';
import { useAuth } from '../../config/AuthContext';

export const Header = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        document.getElementById("main-wrapper").classList.toggle("show-sidebar");
        document.querySelectorAll(".sidebarmenu").forEach((el) => {
            el.classList.toggle("close");
        });
        const dataTheme = document.body.getAttribute("data-sidebartype");
        if (dataTheme === "full") {
            document.body.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
            document.body.setAttribute("data-sidebartype", "full");
        }
    };

    const { profileData } = EmployeeData();
    const dispatch = useDispatch();
    // const profileData = useSelector(state => state.profileData);

    // useEffect(() => {
    //     if (!profileData) {
    //         dispatch(fetchProfileData());
    //     }
    //     else { }
    // }, [dispatch, profileData]);

    const userName = profileData?.first_name ?? 'N/A';
    const userEmail = profileData?.email ?? 'N/A';

    const { setIsAuthenticated } = useAuth();
    const handleLogout = async (e) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out of your account.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, log out',
            cancelButtonText: 'No, cancel',
            reverseButtons: true
        });
        if (result.isConfirmed) {
            dispatch(userLogout(setIsAuthenticated, navigate));
        }
    };

    return (
        <>
            <header className="topbar">
                <div className="with-vertical">
                    <nav className="navbar navbar-expand-lg p-0">
                        <ul className="navbar-nav">
                            <li className="nav-item nav-icon-hover-bg rounded-circle ms-n2">
                                <button
                                    className="nav-link sidebartoggler"
                                    id="headerCollapse"
                                    onClick={handleSidebarToggle}
                                    aria-label="Toggle Sidebar"
                                >
                                    <i className="ti ti-menu-2"></i>
                                </button>
                            </li>
                        </ul>

                        <div className="d-block d-lg-none py-4">
                            <a href="/" className="text-nowrap logo-img">
                                <img
                                    src="./assets/image/Savvyyy.png"
                                    className="dark-logo logo-img3"
                                    alt="Logo-Dark"
                                />
                                {/* <img
              src="https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/logos/light-logo.svg"
              className="light-logo"
              alt="Logo-light"
            /> */}
                            </a>
                        </div>
                        <a
                            className="navbar-toggler nav-icon-hover-bg rounded-circle p-0 mx-0 border-0"
                            href="#"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="ti ti-dots fs-7"></i>
                        </a>
                        <div
                            className="collapse navbar-collapse justify-content-end"
                            id="navbarNav"
                        >
                            <div className="d-flex align-items-center justify-content-between">

                                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                                    <li className="nav-item nav-icon-hover-bg rounded-circle dropdown">
                                        <a
                                            className="nav-link position-relative"
                                            href="#" onClick={(e) => e.preventDefault()}
                                            id="drop2"
                                            aria-expanded="false"
                                        >
                                            <i className="ti ti-bell-ringing"></i>
                                            <div className="notification bg-primary rounded-circle"></div>
                                        </a>
                                        <div
                                            className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                                            aria-labelledby="drop2"
                                        >
                                            <div className="d-flex align-items-center justify-content-between py-3 px-7">
                                                <h5 className="mb-0 fs-5 fw-semibold">Notifications</h5>
                                                <span className="badge text-bg-primary rounded-4 px-3 py-1 lh-sm"> 0 new </span>
                                            </div>
                                            <div className="message-body" data-simplebar>
                                                {/* <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                                            <span className="me-3">
                                                <img
                                                    src="https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/profile/user-7.jpg"
                                                    alt="user"
                                                    className="rounded-circle"
                                                    width="48"
                                                    height="48"
                                                />
                                            </span>
                                            <div className="w-100">
                                                <h6 className="mb-1 fw-semibold lh-base">
                                                    Roman Joined the Team!
                                                </h6>
                                                <span className="fs-2 d-block text-body-secondary">
                                                    Congratulate him
                                                </span>
                                            </div>
                                        </a> */}
                                            </div>
                                            <div className="py-6 px-7 mb-1">
                                                <button className="btn btn-outline-primary w-100">
                                                    See All Notifications
                                                </button>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link pe-0"
                                            href="#" onClick={(e) => e.preventDefault()}
                                            id="drop1"
                                            aria-expanded="false"
                                        >
                                            <div className="d-flex align-items-center">
                                                <div className="user-profile-img">
                                                    <img
                                                        src="./assets/image/profile_image/user-1.jpg"
                                                        className="rounded-circle"
                                                        width="35"
                                                        height="35"
                                                        alt="modernize-img"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <div
                                            className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                                            aria-labelledby="drop1"
                                        >
                                            <div
                                                className="profile-dropdown position-relative"
                                                data-simplebar
                                            >
                                                <div className="py-3 px-7 pb-0">
                                                    <h5 className="mb-0 fs-5 fw-semibold">User Profile</h5>
                                                </div>
                                                <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                                                    <img
                                                        src="./assets/image/profile_image/user-1.jpg"
                                                        className="rounded-circle"
                                                        width="80"
                                                        height="80"
                                                        alt="modernize-img"
                                                    />
                                                    <div className="ms-3">
                                                        <h5 className="mb-1 fs-3">{userName}</h5>
                                                        {/* <span className="mb-1 d-block">Designer</span> */}
                                                        <p className="mb-0 d-flex align-items-center gap-2">
                                                            <i className="ti ti-mail fs-4"></i> {userEmail}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="message-body">
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate("/profile");
                                                        }}
                                                        className="py-8 px-7 mt-8 d-flex align-items-center"
                                                    >
                                                        <span className="d-flex align-items-center justify-content-center text-bg-light rounded-1 p-6">
                                                            <img
                                                                src="https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/svgs/icon-account.svg"
                                                                alt="modernize-img"
                                                                width="24"
                                                                height="24"
                                                            />
                                                        </span>
                                                        <div className="w-100 ps-3">
                                                            <h6 className="mb-1 fs-3 fw-semibold lh-base">
                                                                My Profile
                                                            </h6>
                                                            <span className="fs-2 d-block text-body-secondary">
                                                                Account Settings
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="d-grid py-4 px-7 pt-8">
                                                    <a href="#"
                                                        onClick={handleLogout}
                                                        className="btn btn-outline-primary">
                                                        Log Out
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
};
