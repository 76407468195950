import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Message } from "../../../../components/AlertMessage/Message";

export const ClaimEditTab3 = ({ showError, handleCheckboxChange, declarationChecked, onFileChange }) => {

    const location = useLocation();
    const { claimData } = location.state || {};

    const [files, setFiles] = useState({
        consultation: null,
        receipt: null,
        report: null,
        prescriptions: null,
    });

    const [checkboxState, setCheckboxState] = useState({
        consultation: false,
        receipt: false,
        report: false,
        prescriptions: false,
    });

    const [documentsUploaded, setDocumentsUploaded] = useState({
        consultation: false,
        receipt: false,
        report: false,
        prescriptions: false,
    });

    useEffect(() => {
        if (claimData) {
            const updatedCheckboxState = {
                consultation: claimData.consultation !== "null",
                receipt: claimData.investigation_bill !== "null",
                report: claimData.investigation_reports !== "null",
                prescriptions: claimData.prescriptions !== "null",
            };
            setCheckboxState(updatedCheckboxState);

            setDocumentsUploaded(updatedCheckboxState);
        }
    }, [claimData]);

    const toggleImageInput = (checkbox, type) => {
        const isChecked = checkbox.checked;
        setCheckboxState(prevState => ({
            ...prevState,
            [type]: isChecked
        }));

        if (!isChecked) {
            setFiles(prev => ({ ...prev, [type]: null }));
            onFileChange({ ...files, [type]: null });
        }
    };

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1048576) {
                Message("File size should not exceed 1MB.", "", "warning");
                setFiles(null);
                event.target.value = '';
            } else {
                setFiles((prev) => ({ ...prev, [type]: file }));
                onFileChange({ ...files, [type]: file });
            }
        }
    };

    return (
        <>
            <div className="tab-pane fade show active">
                <section>
                    <div className="row d-flex gap-1">
                        <h5 className="card-title mb-3">Claim Document Checklist</h5>

                        {/* Prescriptions/Consultation */}
                        <div className="col-md-12 claim_document">
                            <div className="col-md-4 d-flex">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox"
                                    id="checkbox1"
                                    checked={checkboxState.prescriptions}
                                    onChange={(e) => toggleImageInput(e.target, 'prescriptions')}
                                />
                                <label className="form-check-label" htmlFor="checkbox1">
                                    Prescriptions/Consultation for the ailment
                                    {documentsUploaded.prescriptions && (
                                        <span className="text-success ms-2">Document already uploaded</span>
                                    )}
                                </label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput1"
                                    name="imageInput1"
                                    className="form-control mt-2"
                                    style={{ display: checkboxState.prescriptions ? 'block' : 'none' }}
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, 'prescriptions')}
                                />
                            </div>
                        </div>

                        {/* Consultation Bill/Receipt */}
                        <div className="col-md-12 claim_document">
                            <div className="col-md-4 d-flex">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox"
                                    id="checkbox2"
                                    checked={checkboxState.receipt}
                                    onChange={(e) => toggleImageInput(e.target, 'receipt')}
                                />
                                <label className="form-check-label" htmlFor="checkbox2">
                                    Consultation bill/Receipt
                                    {documentsUploaded.receipt && (
                                        <span className="text-success ms-2">Document already uploaded</span>
                                    )}
                                </label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput2"
                                    name="imageInput2"
                                    className="form-control mt-2"
                                    style={{ display: checkboxState.receipt ? 'block' : 'none' }}
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, 'receipt')}
                                />
                            </div>
                        </div>

                        {/* Investigation Bill/Receipt */}
                        <div className="col-md-12 claim_document">
                            <div className="col-md-4 d-flex">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox"
                                    id="checkbox3"
                                    checked={checkboxState.report}
                                    onChange={(e) => toggleImageInput(e.target, 'report')}
                                />
                                <label className="form-check-label" htmlFor="checkbox3">
                                    Investigation bill/Receipt
                                    {documentsUploaded.report && (
                                        <span className="text-success ms-2">Document already uploaded</span>
                                    )}
                                </label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput3"
                                    name="imageInput3"
                                    className="form-control mt-2"
                                    style={{ display: checkboxState.report ? 'block' : 'none' }}
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, 'report')}
                                />
                            </div>
                        </div>

                        {/* Investigation Reports */}
                        <div className="col-md-12 mb-5 claim_document">
                            <div className="col-md-4 d-flex">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox"
                                    id="checkbox4"
                                    checked={checkboxState.consultation}
                                    onChange={(e) => toggleImageInput(e.target, 'consultation')}
                                />
                                <label className="form-check-label" htmlFor="checkbox4">
                                    Investigation reports
                                    {documentsUploaded.consultation && (
                                        <span className="text-success ms-2">Document already uploaded</span>
                                    )}
                                </label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput4"
                                    name="imageInput4"
                                    className="form-control mt-2"
                                    style={{ display: checkboxState.consultation ? 'block' : 'none' }}
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, 'consultation')}
                                />
                            </div>
                        </div>

                    </div>

                    {/* Declaration Section */}
                    <form action="#" className="tab-wizard wizard-circle">
                        <div className="row">
                            <h5 className="card-title mb-3">Declaration</h5>
                            <div className="col-md-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input success check-outline outline-success"
                                        type="checkbox"
                                        id="termsAndConditions"
                                        checked={declarationChecked}
                                        onChange={(e) => handleCheckboxChange(e.target)}
                                    />
                                    <label className="form-check-label" htmlFor="termsAndConditions">
                                        I hereby declare that the information furnished in this Claim Form is true & correct to the best of my knowledge & belief. If I have made any false or untrue statement, suppression or concealment of any material fact, my right to claim reimbursement shall be forfeited. I also consent & authorize TPA or insurance company to seek necessary medical information from any hospital / Medical Practitioner who has attended on the person against whom this claim is made. I hereby declare that I have included all the Bills / receipts for the purpose of this claim & that I will not be making any supplementary claim except the Post - hospitalisation claim, if any.
                                    </label>
                                    {showError && (
                                        <div className="text-danger mt-2">This checkbox is required.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>

                </section>
            </div>
        </>
    );
};
