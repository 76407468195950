import axios from "axios";
import { baseURL } from "./APIConfig";
import { userLogout } from '../module/User/UserAction';
import store from '../module/Store';

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? baseURL.development : baseURL.live,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// const getFormData = (object) => {
//     const formData = new FormData();
//     Object.keys(object).forEach((key) => {
//         formData.append(key, object[key]);
//     });
//     return formData;
// };


export const APICall = async (
    method = 'get',
    data = null,
    endpoint = '',
    options = {}
) => {
    try {
        const config = {
            method: method.toLowerCase(),
            url: endpoint,
            data: data,
            // headers: {
            //     'Content-Type': 'multipart/form-data' // multi form-data
            // },
            ...options,
        };

        const response = await axiosInstance(config);
        return response.data;
    } catch (error) {

        if (error.response && error.response.status === 401) {
            const { setIsAuthenticated, navigate } = store.getState().auth;
            store.dispatch(userLogout(setIsAuthenticated, navigate));

            return Promise.reject(new Error('Session expired, logged out.'));
        }

        throw error;
    }
};

export { axiosInstance };