import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData, fetchDashboardData, fetchBeneficiaryData, fetchPolicyData, fetchEcardData, fetchClaimsData, fetchHospitalData, fetchAppointmentData } from "../module/User/UserAction";

export const EmployeeData = () => {
    const dispatch = useDispatch();
    const profileData = useSelector((state) => state.profileData);
    const dashboardData = useSelector((state) => state.dashboardData);
    const beneficiaryData = useSelector(state => state.beneficiaryData);
    const policyData = useSelector(state => state.policyData);
    const ecardData = useSelector(state => state.ecardData);
    const claimsData = useSelector(state => state.claimsData);
    const hospitalData = useSelector(state => state.hospitalData);
    const appointmentData = useSelector(state => state.appointmentData);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading && !dashboardData && !profileData && !beneficiaryData && !policyData && !ecardData && !claimsData && !hospitalData && !appointmentData) {
            dispatch(fetchDashboardData());
            dispatch(fetchProfileData());
            dispatch(fetchBeneficiaryData());
            dispatch(fetchPolicyData());
            dispatch(fetchEcardData());
            dispatch(fetchClaimsData());
            dispatch(fetchHospitalData());
            dispatch(fetchAppointmentData());
            setIsLoading(false);
        }
    }, [dispatch, isLoading, dashboardData, profileData, beneficiaryData, policyData, ecardData, claimsData, hospitalData, appointmentData]);

    return { profileData, dashboardData, beneficiaryData, policyData, ecardData, claimsData, hospitalData, appointmentData };
};
