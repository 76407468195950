export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const POLICY_DATA = 'POLICY_DATA';
export const BENEFICIARY_DATA = 'BENEFICIARY_DATA';
export const ECARD_DATA = 'ECARD_DATA';
export const HOSPITAL_DATA = 'HOSPITAL_DATA';
export const APPOINTMENT_DATA = 'APPOINTMENT_DATA';
export const CLAIMS_DATA = 'CLAIMS_DATA';
export const BOOK_APPOINTMENT = 'BOOK_APPOINTMENT';
export const BENEFICIARY_UPDATE = "BENEFICIARY_UPDATE";
export const CONTACT_DATA = "CONTACT_DATA";
export const ADD_CLAIM = "ADD_CLAIM";
export const COUNTRIES_DATA = "COUNTRIES_DATA";
export const STATES_DATA = "STATES_DATA";
export const PROFILE_DATA = "PROFILE_DATA";
export const PASSWORD_DATA = "PASSWORD_DATA";