import React from "react";

const ReusableForm = ({ fields, onReset, onSubmit, onChange }) => {
    return (
        <div className="card card-body">
            <div className="row">
                {fields.map((field, index) => (
                    <div className={`col-md-${field.col || 4}`} key={index}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor={field.id}>{field.label}</label>
                            {field.type === 'select' ? (
                                <select className="form-select" id={field.id} name={field.name} defaultValue="" onChange={onChange}>
                                    <option defaultValue disabled>{field.placeholder}</option>
                                    {field.options.map((option, optIndex) => (
                                        <option key={optIndex} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type={field.type}
                                    id={field.id}
                                    name={field.name}
                                    className="form-control"
                                    placeholder={field.placeholder}
                                    onChange={onChange}
                                />
                            )}
                        </div>
                    </div>
                ))}
                <div className="col-12">
                    <div className="d-md-flex align-items-center">
                        <div className="ms-auto mt-3 mt-md-0">
                            <button type="button" className="btn bg-danger-subtle text-danger me-2" onClick={onReset}>
                                Reset
                            </button>
                            <button type="button" className="btn btn-primary" onClick={onSubmit}>
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReusableForm;
