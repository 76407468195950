import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Message } from "../../components/AlertMessage/Message";
import { useDispatch } from "react-redux";
import { userLogin } from "../../module/User/UserAction";
import { useAuth } from '../../config/AuthContext';

export const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [rememberDevice, setRememberDevice] = useState(true);
    const [validated, setValidated] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();
    const { setIsAuthenticated } = useAuth();

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (!email) {
            setEmailError("Please enter your email.");
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailError("Please enter a valid email.");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError("Please enter your password.");
            isValid = false;
        } else if (password.length < 6) {
            setPasswordError("Password must be at least 6 characters long.");
            isValid = false;
        } else {
            setPasswordError("");
        }

        setValidated(true);

        if (isValid) {
            const payload = { email, password };
            try {
                const authToken = await dispatch(userLogin(payload));
                if (authToken) {
                    setIsAuthenticated(true);
                    navigate('/dashboard');
                }
            } catch (error) { }
        }

    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (emailError) {
            setEmailError("");
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (passwordError) {
            setPasswordError("");
        }
    };

    return (
        <div id="main-wrapper" className="auth-customizer-none">
            <div className="position-relative overflow-hidden radial-gradient min-vh-100 w-100">
                <div className="position-relative z-index-5">
                    <div className="row">
                        <div className="col-xl-7 col-xxl-8">
                            <a href="/" className="text-nowrap logo-img logo-img2 d-block px-4 py-9 w-30 h-auto">
                                <img src="./assets/image/Savvyyy.png" className="dark-logo w-60 h-auto" alt="Logo-Dark" />
                            </a>
                            <div className="d-none d-xl-flex align-items-center justify-content-center h-n80">
                                <img src="https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/backgrounds/login-security.svg" alt="modernize-img" className="img-fluid" width="500" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-xxl-4">
                            <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                                <div className="auth-max-width col-sm-8 col-md-6 col-xl-7 px-4">
                                    <h2 className="mb-1 fs-7 fw-bolder">Welcome to savvvyy</h2>
                                    <p className="mb-7">Employee Dashboard</p>
                                    <div className="position-relative text-center my-4">
                                        <p className="mb-0 fs-4 px-3 d-inline-block bg-body text-dark z-index-5 position-relative">Sign In</p>
                                        <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
                                    </div>
                                    <form onSubmit={handleLogin} noValidate>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className={`form-control ${validated && emailError ? "is-invalid" : ""}`}
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                value={email}
                                                onChange={handleEmailChange}
                                                required
                                            />
                                            {validated && emailError && (
                                                <div className="invalid-feedback">{emailError}</div>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <div className="d-flex justify-content-between">
                                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                                {!showPassword ? (
                                                    <div className="me-1 cursor-pointer no-select no-tap-highlight" onClick={handleTogglePasswordVisibility}>
                                                        <i className="ti ti-eye me-1"></i>Show
                                                    </div>
                                                ) : (
                                                    <div className="me-1 cursor-pointer no-select no-tap-highlight" onClick={handleTogglePasswordVisibility}>
                                                        <i className="ti ti-eye-off me-1"></i>Hide
                                                    </div>
                                                )}
                                            </div>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                className={`form-control ${validated && passwordError ? "is-invalid" : ""}`}
                                                id="exampleInputPassword1"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                required
                                            />
                                            {validated && passwordError && (
                                                <div className="invalid-feedback">{passwordError}</div>
                                            )}
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary w-100 py-8 mb-4 rounded-2 sign_in_button">
                                            Sign In
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
