import React from 'react';

import { Login } from '../screen/Login/Login';
import { Dashboard } from '../screen/Dashboard/Dashboard';
import { Profile } from '../screen/Profile/Profile';
import { Policy } from '../screen/Policy/Policy';
import { Beneficiary } from '../screen/Beneficiary/Beneficiary';
import { ECard } from '../screen/Ecard/Ecard';
import { AllClaims } from '../screen/Claims/AllClaims/AllClaims';
import { NewClaim } from '../screen/Claims/NewClaim/NewClaim';
import { ClaimEdit } from '../screen/Claims/AllClaims/ClaimEdit/ClaimEdit';
import { CashlessFlow } from '../screen/Claims/ClaimProcessFlow/CashlessFlow/CashlessFlow';
import { NonCashlessFlow } from '../screen/Claims/ClaimProcessFlow/NonCashlessFlow/NonCashlessFlow';
import { ContactList } from '../screen/ContactList/ContactList';
import { BookAppointment } from '../screen/Wellness/BookAppointment/BookAppointment';
import { ViewAppointment } from '../screen/Wellness/ViewAppointment/ViewAppointment';
import { NetworkHospital } from '../screen/NetworkHospital/NetworkHospital';

const authRoutes = () => [
    { path: '/', component: <Login /> },
];


const userRoutes = [
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/profile', component: <Profile /> },
    { path: '/policy', component: <Policy /> },
    { path: '/beneficiary', component: <Beneficiary /> },
    { path: '/e-card', component: <ECard /> },
    { path: '/all-claims', component: <AllClaims /> },
    { path: '/new-claim', component: <NewClaim /> },
    { path: '/claim-edit', component: <ClaimEdit /> },
    { path: '/cashless-flow', component: <CashlessFlow /> },
    { path: '/non-cashless-flow', component: <NonCashlessFlow /> },
    { path: '/contact-list', component: <ContactList /> },
    { path: '/book-appointment', component: <BookAppointment /> },
    { path: '/view-appointment', component: <ViewAppointment /> },
    { path: '/network-hospital', component: <NetworkHospital /> },
];

export { userRoutes, authRoutes };
