import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { SideBar } from "../../components/SideNavigation/SideNavigation";
import { formatDate, formatCurrency } from '../../utility/CommonConstant';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardData, fetchProfileData } from '../../module/User/UserAction';
import { EmployeeData } from '../../utility/EmployeeData';

export const Dashboard = () => {
    const { profileData, dashboardData } = EmployeeData();
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const dashboardData = useSelector(state => state.dashboardData);
    // const profileData = useSelector(state => state.profileData);

    // useEffect(() => {

    //     if (!dashboardData || !profileData) {
    //         dispatch(fetchDashboardData());
    //         dispatch(fetchProfileData());
    //     }
    //     else { }
    // }, [dispatch, dashboardData, profileData]);

    const userName = profileData?.first_name ?? 'N/A';
    const totalClaims = dashboardData?.totalClaims ?? '0';
    const policyName = dashboardData?.salesPolicies?.[0]?.policy_id?.name ?? 'Loading...';
    const totalBeneficiary = dashboardData?.totalBeneficiary ?? '0';
    const date_of_joining = dashboardData?.salesPolicies?.[0]?.date_of_joining ?? '0';
    const policy_end_date = dashboardData?.salesPolicies?.[0]?.policy_end_date ?? '0';
    const sum_insured = dashboardData?.salesPolicies?.[0]?.policy_id?.sum_insured ?? '0';

    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-8 d-flex align-items-stretch dash-1">
                                    <div className="card w-100 bg-primary-subtle overflow-hidden shadow-none">
                                        <div className="card-body position-relative">
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <div className="d-flex align-items-center mb-7">
                                                        <div className="rounded-circle overflow-hidden me-6">
                                                            <img src="./assets/image/profile_image/user-1.jpg"
                                                                alt="modernize-img" width="40" height="40" />
                                                        </div>
                                                        <h5 className="fw-semibold mb-0 fs-5">Welcome back {userName}!</h5>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="border-end pe-4 border-muted border-opacity-10">
                                                            <h3 className="mb-1 fw-semibold fs-8 d-flex align-content-center">
                                                                {totalClaims}
                                                                <i className="ti ti-arrow-up-right fs-5 lh-base text-success"></i>
                                                            </h3>
                                                            <p className="mb-0 text-dark">Total Claims</p>
                                                        </div>
                                                        <div className="ps-4">
                                                            <h3 className="mb-1 fw-semibold fs-8 d-flex align-content-center">00%<i
                                                                className="ti ti-arrow-up-right fs-5 lh-base text-success"></i>
                                                            </h3>
                                                            <p className="mb-0 text-dark">Total Utilization</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="welcome-bg-img mb-n7 text-end">
                                                        <img src="./assets/image/welcome-bg.svg" alt="modernize-img"
                                                            className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex align-items-stretch flex-column dash-2">
                                    <div className="card w-100">
                                        <div className="card-body d-flex align-items-stretch">
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    <h4 className="card-title mb-9 fw-semibold">
                                                        Validity period
                                                    </h4>
                                                    <p className="fw-semibold mb-0">{policyName}</p>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <p className="text-dark me-1 fs-2 mb-0">{formatDate(date_of_joining)} To {formatDate(policy_end_date)}</p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-4">
                                                            <span className="fs-2 next_premium">{formatCurrency(sum_insured)} Rs.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="">
                                                            <img src="./assets/image/premium.avif"
                                                                className="shadow-warning rounded-2" alt="modernize-img" width="72"
                                                                height="72" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="container-fluid px-4">
                        <div className="row">
                            <div className="col-sm-6 col-xl-4">
                                <div className="card bg-primary-subtle shadow-none">
                                    <div className="card-body p-4 ">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="round rounded text-bg-primary d-flex align-items-center justify-content-center">
                                                <i className="ti ti-cards fs-10"></i>
                                            </div>
                                            <div>
                                                <h6 className="mb-0 ms-4 mb-3">Sum Insured</h6>
                                                <h3 className="mb-0 ms-4 fw-semibold fs-7">{formatCurrency(sum_insured)} Rs.</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-4">
                                <div className="card bg-success-subtle shadow-none">
                                    <div className="card-body p-4 ">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="round rounded text-bg-success d-flex align-items-center justify-content-center">
                                                <i className="ti ti-premium-rights fs-10"></i>

                                            </div>
                                            <div>
                                                <h6 className="mb-0 ms-4 mb-3">Balance sum insured</h6>
                                                <h3 className="mb-0 ms-4 fw-semibold fs-7">1,00,000 Rs</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-4">
                                <div className="card bg-danger-subtle shadow-none">
                                    <div className="card-body p-4 ">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="round rounded text-bg-danger d-flex align-items-center justify-content-center">
                                                <i className="ti ti-users fs-10"></i>

                                            </div>
                                            <div>
                                                <h6 className="mb-0 ms-4 mb-3">Total Beneficiary</h6>
                                                <h3 className="mb-0 ms-4 fw-semibold fs-7">{totalBeneficiary}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 d-flex align-items-strech">
                                <div className="card w-100">
                                    <div className="card-body p-4">
                                        <h4 className="card-title fw-semibold">Claims</h4>
                                        <p className="card-subtitle">Submit your reimbursement and domiciliary claims online or
                                            track your claim status in time</p>
                                        <div className="card overflow-hidden mt-9 justify-content-center align-items-center">
                                            <img src="./assets/image/claims.avif" alt="bg-card" className="dashboard_card_image" />
                                        </div>
                                        <a href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/all-claims");
                                            }} className="btn bg-primary-subtle text-primary w-100 mt-3">
                                            View Claims
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 d-flex align-items-stretch">
                                <div className="card w-100">
                                    <div className="card-body p-4">
                                        <h4 className="card-title fw-semibold">Policy</h4>
                                        <p className="card-subtitle">Know your policy terms & conditions, enroll beneficiaries,
                                            download e-card and more.</p>
                                        <div className="card overflow-hidden mt-9 justify-content-center align-items-center">
                                            <img src="./assets/image/policy.avif" alt="bg-card" className="dashboard_card_image" />
                                        </div>
                                        <a href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/policy");
                                            }} className="btn bg-primary-subtle text-primary w-100 mt-3">
                                            View Policy
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 d-flex align-items-strech">
                                <div className="card w-100">
                                    <div className="card-body p-4">
                                        <h4 className="card-title fw-semibold">Wellness</h4>
                                        <p className="card-subtitle">Avail all benefits under your corporate wellness programs.</p>
                                        <div className="card overflow-hidden mt-9 justify-content-center align-items-center">
                                            <img src="./assets/image/wellness.jpg" alt="bg-card" className="dashboard_card_image" />
                                        </div>
                                        <a href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/view-appointment");
                                            }} className="btn bg-primary-subtle text-primary w-100 mt-3">
                                            View
                                            Wellness
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
