import React, { useState } from "react";
import { Message } from "../../components/AlertMessage/Message";
import { useDispatch } from 'react-redux';
import { changePassword } from '../../module/User/UserAction';
import { LoadingBtn } from "../../components//Action/Loading";


export const ChangePass = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [showPasswords, setShowPasswords] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        switch (id) {
            case 'currentPassword':
                setCurrentPassword(value);
                setErrors(prevErrors => ({ ...prevErrors, currentPassword: '' }));
                break;
            case 'newPassword':
                setNewPassword(value);
                setErrors(prevErrors => ({ ...prevErrors, newPassword: '' }));
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                setErrors(prevErrors => ({ ...prevErrors, confirmPassword: '' }));
                break;
            default:
                break;
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        };

        if (!currentPassword) {
            newErrors.currentPassword = 'Current password is required.';
            valid = false;
        }
        if (!newPassword) {
            newErrors.newPassword = 'New password is required.';
            valid = false;
        }
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
            valid = false;
        }
        if (!confirmPassword) {
            newErrors.confirmPassword = 'Confirm password is required.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (validateForm()) {
            const payload = {
                currentPassword,
                newPassword,
                confirmPassword,
            };

            try {
                const response = await dispatch(changePassword(payload));
                Message("Password Updated successfully!", "", "success");
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } catch (error) {
                Message("Try Again!", "Error updating password", "error");
            }
        }
        setIsLoading(false);
    };

    return (
        <div className="col-12">
            <div className="card w-100 border position-relative overflow-hidden">
                <div className="card-body p-4">
                    <h4 className="card-title mb-4">Change Password</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="currentPassword" className="form-label">Current Password</label>
                            <input
                                type={showPasswords ? 'text' : 'password'}
                                className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`}
                                id="currentPassword"
                                value={currentPassword}
                                onChange={handleInputChange}
                            />
                            {errors.currentPassword && <div className="invalid-feedback">{errors.currentPassword}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="newPassword" className="form-label">New Password</label>
                            <input
                                type={showPasswords ? 'text' : 'password'}
                                className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                                id="newPassword"
                                value={newPassword}
                                onChange={handleInputChange}
                            />
                            {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                            <input
                                type={showPasswords ? 'text' : 'password'}
                                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={handleInputChange}
                            />
                            {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                        </div>

                        <div className="form-check mb-2">
                            <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                id="showPasswords"
                                checked={showPasswords}
                                onChange={() => setShowPasswords(!showPasswords)}
                            />
                            <label className="form-check-label" htmlFor="showPasswords">
                                Show Passwords
                            </label>
                        </div>

                        <div className="col-12 d-flex align-items-center justify-content-end gap-6 upd-profile">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? <LoadingBtn /> : 'Update Password'}
                            </button>
                            <button type="button" className="btn bg-danger-subtle text-danger">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
