import React, { useState, useEffect } from "react";
import { SideBar } from "../../components/SideNavigation/SideNavigation";
import { Header } from "../../components/Header/Header";
import PageHeader from "../../components/Reusable/PageHeader/PageHeader";
import ReusableTable from "../../components/Reusable/ReuseTable/ReuseTable";
import ReusableForm from "../../components/Reusable/ReuseFilter/ReuseFilter";
import { useDispatch, useSelector } from 'react-redux';
import { fetchEcardData } from '../../module/User/UserAction';
import { downloadAnyFile } from '../../utility/CommonConstant';
import { EmployeeData } from '../../utility/EmployeeData';

export const ECard = () => {

    // const dispatch = useDispatch();
    // const ecardData = useSelector(state => state.ecardData);

    const { ecardData } = EmployeeData();

    const [filteredData, setFilteredData] = useState(ecardData || []);
    const [filterValues, setFilterValues] = useState({
        employeeId: '',
        beneficiaryName: '',
        balanceSI: '',
    });
    useEffect(() => {
        if (ecardData) {
            setFilteredData(ecardData);
        } else { }
    }, [ecardData]);

    const handleReset = () => {
        setFilteredData(ecardData);
    };
    const handleFilter = () => {
        const filtered = ecardData.filter(item => {
            return (
                (!filterValues.employeeId || item?.beneficiary_id?.employee_id?.toString().includes(filterValues.employeeId)) &&
                (!filterValues.beneficiaryName || item?.beneficiary_id?.beneficiary_name?.toLowerCase().includes(filterValues.beneficiaryName.toLowerCase())) &&
                (!filterValues.balanceSI || item.balance_si?.toString() === filterValues.balanceSI)
            );
        });
        setFilteredData(filtered);
    };



    const eCardHeaders = [
        'Image', 'Employee Id', 'Beneficiary Name', 'Balance SI', 'Action'
    ];

    const eCardTableData = filteredData?.map(item => [
        <img src="./assets/image/pdfimg.jpg" width="60" alt="eCard" />,
        item?.beneficiary_id?.employee_id ?? 'N/A',
        item?.beneficiary_id?.beneficiary_name ?? 'N/A',
        "-",
        <button type="button" className="btn" onClick={() => downloadAnyFile(item?.file)}>
            <i className="ti ti-download"></i>
        </button>
    ]) || [];


    const eCardFields = [
        { id: 'employee-id', name: 'employeeId', label: 'Employee Id', type: 'text', placeholder: 'Enter Employee Id' },
        { id: 'beneficiary-name', name: 'beneficiaryName', label: 'Beneficiary Name', type: 'text', placeholder: 'Enter Beneficiary Name' },
        { id: 'balance-si', name: 'balanceSI', label: 'Balance SI', type: 'number', placeholder: 'Enter Balance SI' }
    ];

    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">
                            <PageHeader
                                title="Download E-Card"
                                pageName="Download E-Card"
                            />
                            <ReusableForm
                                fields={eCardFields}
                                onReset={handleReset}
                                onSubmit={handleFilter}
                                onChange={(e) => setFilterValues({
                                    ...filterValues,
                                    [e.target.name]: e.target.value
                                })}
                            />
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="card-title">E-Card List</h4>
                                    </div>
                                    <div className="table-responsive">
                                        <ReusableTable
                                            headers={eCardHeaders}
                                            data={eCardTableData}
                                            noDataText="No Data Found"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
