export const baseURL = {
    live: "http://54.215.217.167:3000/api",
    development: "http://54.215.217.167:3000/api",
    local: "/",
};

export const APIEndpoint = {
    login: "login",
    profile: "employee",
    dashboard: "employee/dashboard",
    policy: "employee/policy",
    beneficiary: "employee/beneficiary",
    ecard: "employee/ecard",
    hospital: "employee/hospital",
    appointment: "employee/appointment",
    claims: "employee/claims",
    contact: "employee/contact",

    countries: "countries",
    states: "states",
    city: "city",

};
