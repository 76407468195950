import React from "react";
import { SideBar } from "../../../../components/SideNavigation/SideNavigation";
import { Header } from "../../../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../components/Reusable/PageHeader/PageHeader";

export const NonCashlessFlow = () => {

    const navigate = useNavigate();


    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />

                    <div className="body-wrapper">
                        <div className="container-fluid">

                            <PageHeader
                                title="Non Cashless Flow"
                                pageName="Non Cashless Flow"
                            />

                            <div className="card card-body">
                                <div className="row my-4">
                                    <img src="./assets/image/picture3.png" alt="modernize-img"
                                        className="img-fluid mb-n4" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
