import { axiosInstance } from '../config/NetworkUtility';

export const setAuthToken = (token, email = '', name = '') => {
    if (token) {
        localStorage.setItem('authToken', token);
        // localStorage.setItem('userEmail', email);
        // localStorage.setItem('userName', name);

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        localStorage.removeItem('authToken');
        // localStorage.removeItem('userEmail');
        // localStorage.removeItem('userName');
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};



export const formatDate = (dateString) => {
    if (!dateString) return 'Invalid Date';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const formatCurrency = (amount) => {
    if (!amount) return '0';

    return new Intl.NumberFormat('en-IN', {
        maximumFractionDigits: 0,
    }).format(amount);
};


export const formatDateTime = (dateString) => {
    if (!dateString) return 'Invalid Date';

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = String(date.getSeconds()).padStart(2, '0');

    return (
        <span>
            {`${year}-${month}-${day}`}
            <span>&nbsp;&nbsp;&nbsp; {`${hours}:${minutes}`} </span>
        </span>
    );
};





const getFileNameFromUrl = (url) => {
    return url.split('/').pop();
};
export const downloadAnyFile = async (url) => {
    try {
        const response = await fetch(url, { mode: 'cors' });
        const blob = await response.blob();
        const urlObject = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = urlObject;
        link.download = getFileNameFromUrl(url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(urlObject);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};