import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { authRoutes, userRoutes } from './allRoutes';
import { useAuth } from '../config/AuthContext';

const MainRoutes = () => {
    const { isAuthenticated } = useAuth();

    return (
        <Routes>
            {isAuthenticated ? (
                userRoutes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.component} />
                ))
            ) : (
                authRoutes().map((route) => (
                    <Route key={route.path} path={route.path} element={route.component} />
                ))
            )}
            <Route path="*" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/" />} />
        </Routes>
    );
};

export default MainRoutes;
