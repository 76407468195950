import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { SideBar } from '../../../../components/SideNavigation/SideNavigation';
import { Header } from '../../../../components/Header/Header';
import { ClaimEditTab1 } from './ClaimEditTab1';
import { ClaimEditTab2 } from './ClaimEditTab2';
import { ClaimEditTab3 } from './ClaimEditTab3';
import { Message } from "../../../../components/AlertMessage/Message";
import { LoadingBtn } from "../../../../components//Action/Loading";
import PageHeader from "../../../../components/Reusable/PageHeader/PageHeader";
import { useDispatch } from 'react-redux';
import { editClaimsData, fetchClaimsData } from '../../../../module/User/UserAction';


export const ClaimEdit = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { claimData } = location.state || {};

    const [patientId, setPatientId] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const [formData, setFormData] = useState({
        client_id: claimData?.client_id?._id,
        employee_id: claimData?.employee_id?._id,
        status: claimData?.status,
        patient_id: claimData?.patient_id?._id,

        patientName: claimData?.patient_id?.beneficiary_name,
        relation: claimData?.relation_with_employee,
        age: claimData?.patient_age,
        gender: claimData?.gender,
        startDate: formatDate(claimData?.treatment_start_date),
        endDate: formatDate(claimData?.treatment_end_date),

        bankHolderName: claimData?.bank_details?.bank_holder_name,
        ifsc: claimData?.bank_details?.ifsc_code,
        accountNumber: claimData?.bank_details?.account_number,
        bankDetails: claimData?.bank_details?.bank_details,
    });


    const [claimTab2Data, setClaimTab2Data] = useState({
        shortDescription: claimData?.nature_of_illness,
        doctorName: claimData?.doctor_name,
        clinicName: claimData?.clinic_name,
        clinicPincode: claimData?.clinic_pincode,
        totalAmountClaimed: claimData?.total_amount_claimed,
    });

    const [expenses, setExpenses] = useState(
        claimData?.claims_bills?.length > 0
            ? claimData.claims_bills.map(item => ({
                billNo: item?.bill_no ?? '',
                billAmount: item?.bill_amount ?? '',
                billType: item?.bill_type ?? '',
                date: formatDate(item?.bill_date) ?? '',
            }))
            : [{ billNo: '', billAmount: '', billType: '', date: '' }]
    );

    const [files, setFiles] = useState({});

    const handleFileChange = (newFiles) => {
        setFiles(prevFiles => ({
            ...prevFiles,
            ...newFiles,
        }));
    };


    const [activeTab, setActiveTab] = useState('userBankDetails');
    const [tab1Completed, setTab1Completed] = useState(false);
    const [tab2Completed, setTab2Completed] = useState(false);
    const [tab1Touched, setTab1Touched] = useState(false);
    const [tab2Touched, setTab2Touched] = useState(false);
    const [declarationChecked, setDeclarationChecked] = useState(false);
    const [showDeclarationError, setShowDeclarationError] = useState(false);

    const handleCheckboxChange = (checkbox) => {
        if (checkbox.id === 'termsAndConditions') {
            setDeclarationChecked(checkbox.checked);
            if (checkbox.checked) {
                setShowDeclarationError(false);
            }
        }
    };

    const handleTabChange = (tab) => {
        if (tab === 'claimDetails' && !tab1Completed) return;
        if (tab === 'declaration' && !tab2Completed) return;
        setActiveTab(tab);

        if (tab === 'claimDetails') setTab1Touched(true);
        if (tab === 'declaration') setTab2Touched(true);
    };

    const handleNextTab = () => {
        if (activeTab === 'userBankDetails') {
            setTab1Touched(true);
            if (tab1Completed) {
                setActiveTab('claimDetails');
            }
        } else if (activeTab === 'claimDetails') {
            setTab2Touched(true);
            if (tab2Completed) {
                setActiveTab('declaration');
            }
        }
    };

    const handlePreviousTab = () => {
        if (activeTab === 'declaration') {
            setActiveTab('claimDetails');
        } else if (activeTab === 'claimDetails') {
            setActiveTab('userBankDetails');
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!declarationChecked) {
            setShowDeclarationError(true);
            return;
        }
        setShowDeclarationError(false);

        const claimsData = new FormData();

        setIsLoading(true);

        claimsData.append('client_id', formData.client_id);
        claimsData.append('employee_id', formData.employee_id);
        claimsData.append('patient_id', patientId || claimData?.patient_id?._id);
        claimsData.append('patient_name', formData.patientName);
        claimsData.append('relation', formData.relation);
        claimsData.append('age', formData.age);
        claimsData.append('gender', formData.gender);
        claimsData.append('status', formData.status);
        claimsData.append('treatment_start_date', formData.startDate);
        claimsData.append('treatment_end_date', formData.endDate);
        claimsData.append('nature_of_illness', claimTab2Data.shortDescription);
        claimsData.append('doctor_name', claimTab2Data.doctorName);
        claimsData.append('clinic_name', claimTab2Data.clinicName);
        claimsData.append('clinic_pincode', claimTab2Data.clinicPincode);
        claimsData.append('total_amount_claimed', claimTab2Data.totalAmountClaimed);

        if (files.consultation) {
            claimsData.append('consultation', files.consultation);
        }
        if (files.receipt) {
            claimsData.append('investigation_bill', files.receipt);
        }
        if (files.report) {
            claimsData.append('investigation_reports', files.report);
        }
        if (files.prescriptions) {
            claimsData.append('prescriptions', files.prescriptions);
        }

        claimsData.append('ifsc_code', formData.ifsc);
        claimsData.append('bank_holder_name', formData.bankHolderName);
        claimsData.append('account_number', formData.accountNumber);
        claimsData.append('bank_details', formData.bankDetails);

        claimsData.append('relation_with_employee', formData.relation);
        claimsData.append('gender', formData.gender);
        claimsData.append('patient_age', formData.age);

        const claimsBillsArray = expenses.map(expense => ({
            bill_no: expense.billNo,
            bill_amount: expense.billAmount,
            bill_type: expense.billType,
            bill_date: expense.date,
        }));

        claimsData.append('bill_details', JSON.stringify(claimsBillsArray));

        try {
            await dispatch(editClaimsData(claimData._id, claimsData));
            Message("Claim Edited successfully!", "", "success").then(() => {
                dispatch(fetchClaimsData());
                navigate('/all-claims');
            });
        } catch (error) {
            Message('Try Again!', "Failed to update claim", "error");
        }

        setIsLoading(false);
    };


    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">

                            <PageHeader
                                title="Edit Claim"
                                pageName="Edit Claim"
                            />

                            <div className="card p-3">
                                <div className="card-body wizard-content claim-card">
                                    <ul className="nav nav-tabs mb-4 d-flex justify-content-between">
                                        <li className="nav-item d-flex flex-column justify-content-center align-items-center cursor-pointer position-relative">
                                            <a
                                                className={`nav-link nav-link2 rounded-circle ${activeTab === 'userBankDetails' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('userBankDetails')}
                                            >
                                                <div className='tab-count'>1</div>
                                            </a>
                                            <span className={`connector ${activeTab === 'claimDetails' || activeTab === 'declaration' ? 'completed' : ''}`}></span>
                                            <div className="tab-label">User and Bank Details</div>
                                        </li>
                                        <li className="nav-item d-flex flex-column justify-content-center align-items-center cursor-pointer position-relative">
                                            <a
                                                className={`nav-link nav-link2 rounded-circle ${activeTab === 'claimDetails' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('claimDetails')}
                                            >
                                                <div className='tab-count'>2</div>
                                            </a>
                                            <span className={`connector ${activeTab === 'declaration' ? 'completed' : ''}`}></span>
                                            <div className="tab-label">Claim Details</div>
                                        </li>
                                        <li className="nav-item d-flex flex-column justify-content-center align-items-center cursor-pointer position-relative">
                                            <a
                                                className={`nav-link nav-link2 rounded-circle ${activeTab === 'declaration' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('declaration')}
                                            >
                                                <div className='tab-count'>3</div>
                                            </a>
                                            <div className="tab-label">Declaration and Claim Submission</div>
                                        </li>
                                    </ul>



                                    <div className="tab-content pt-2">
                                        {activeTab === 'userBankDetails' && (
                                            <ClaimEditTab1
                                                formData={formData}
                                                setFormData={setFormData}
                                                setTab1Completed={setTab1Completed}
                                                setPatientId={setPatientId}
                                                isTabTouched={tab1Touched}
                                            />
                                        )}

                                        {activeTab === 'claimDetails' && (
                                            <ClaimEditTab2
                                                formData={claimTab2Data}
                                                setFormData={setClaimTab2Data}
                                                setTab2Completed={setTab2Completed}
                                                expenses={expenses}
                                                setExpenses={setExpenses}
                                                isTabTouched={tab2Touched}
                                            />
                                        )}

                                        {activeTab === 'declaration' && (
                                            <ClaimEditTab3
                                                showError={showDeclarationError}
                                                declarationChecked={declarationChecked}
                                                onFileChange={handleFileChange}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />
                                        )}
                                    </div>

                                    <div className="tab-navigation-buttons d-flex justify-content-end gap-3 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-pre"
                                            onClick={handlePreviousTab}
                                            disabled={activeTab === 'userBankDetails'}
                                        >
                                            Previous
                                        </button>
                                        {activeTab === 'declaration' ? (
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-primary2"
                                                onClick={handleSubmit}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <LoadingBtn /> : 'Submit'}
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-primary2"
                                                onClick={handleNextTab}
                                            >
                                                Next
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
