import React from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { deleteClaimsData, fetchClaimsData } from '../../module/User/UserAction';

export const DeleteButton = ({ claimId }) => {
    const dispatch = useDispatch();
    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteClaimsData(claimId));
                Swal.fire(
                    'Deleted!',
                    'Your record has been deleted.',
                    'success'
                );
                await dispatch(fetchClaimsData(claimId));
            }
        });
    };

    return (
        <i
            className="ti ti-trash cursor-pointer d-btn"
            onClick={handleDelete}
        ></i>
    );
};
