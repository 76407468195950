import { USER_LOGIN, USER_LOGOUT, DASHBOARD_DATA, POLICY_DATA, BENEFICIARY_DATA, ECARD_DATA, HOSPITAL_DATA, APPOINTMENT_DATA, CLAIMS_DATA, ADD_CLAIM, BOOK_APPOINTMENT, PROFILE_DATA, PASSWORD_DATA, BENEFICIARY_UPDATE, CONTACT_DATA, COUNTRIES_DATA } from "./UserModules";
import { APICall } from '../../config/NetworkUtility';
import { APIEndpoint } from '../../config/APIConfig';
import { setAuthToken } from '../../utility/CommonConstant';
import { Message } from "../../components/AlertMessage/Message";

export const userLogin = (payload) => {
    return async (dispatch) => {
        try {
            const result = await APICall('post', payload, APIEndpoint.login);
            if (result.status === 1) {
                const authToken = result.data?.accessToken;
                // const email = result.data?.email || '';
                // const name = result.data?.name || '';
                const userType = result.data?.user_type;

                if (userType !== 'employee') {
                    Message('Login Failed', "You do not have the necessary permissions to log in.", "error");
                    return Promise.reject();
                }

                if (authToken) {
                    setAuthToken(authToken);
                    // setAuthToken(authToken, email, name);
                }

                dispatch({
                    type: USER_LOGIN,
                    payload: result.data,
                });

                dispatch(fetchAllData());

                Message('Login Success', "", "success");
                return Promise.resolve(authToken);
            } else {
                Message('Login Failed', "", "error");
                return Promise.reject();
            }
        } catch (error) {
            Message('Try Again!', "email address and password are incorrect", "error");
            return Promise.reject();
        }
    };
};


export const fetchAllData = () => {
    return async (dispatch) => {
        await Promise.all([
            dispatch(fetchDashboardData()),
            dispatch(fetchPolicyData()),
            dispatch(fetchProfileData()),
            dispatch(fetchBeneficiaryData()),
            dispatch(fetchEcardData()),
            dispatch(fetchHospitalData()),
            dispatch(fetchAppointmentData()),
            dispatch(fetchClaimsData()),
            dispatch(fetchContactData()),
            dispatch(fetchCountries())
        ]);
    };
};


export const userLogout = (setIsAuthenticated, navigate) => {
    return (dispatch) => {
        return new Promise((resolve) => {
            localStorage.removeItem('authToken');
            // localStorage.removeItem('userEmail');
            // localStorage.removeItem('userName');

            setIsAuthenticated(false);

            dispatch({
                type: USER_LOGOUT,
            });

            Message('Logout Successful', "", "success");
            navigate('/');
            resolve();
        });
    };
};





export const fetchDashboardData = (payload) => {
    return async (dispatch) => {
        try {
            const dashboardData = await APICall('get', payload, APIEndpoint.dashboard);

            dispatch({
                type: DASHBOARD_DATA,
                payload: dashboardData.data,
            });

            return dashboardData;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const fetchPolicyData = (payload) => {
    return async (dispatch) => {
        try {
            const policyData = await APICall('get', payload, APIEndpoint.policy);

            dispatch({
                type: POLICY_DATA,
                payload: policyData.data,
            });

            return policyData;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const fetchBeneficiaryData = (payload) => {
    return async (dispatch) => {
        try {
            const beneficiaryData = await APICall('get', payload, APIEndpoint.beneficiary);

            dispatch({
                type: BENEFICIARY_DATA,
                payload: beneficiaryData.data,
            });

            return beneficiaryData;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const editBeneficiaryData = (benId, payload) => {
    return async (dispatch) => {
        try {
            const response = await APICall('put', payload, `${APIEndpoint.beneficiary}/edit/${benId}`);

            dispatch({
                type: BENEFICIARY_UPDATE,
                payload: response.data,
            });

            return response;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const fetchEcardData = (payload) => {
    return async (dispatch) => {
        try {
            const ecardData = await APICall('get', payload, APIEndpoint.ecard);

            dispatch({
                type: ECARD_DATA,
                payload: ecardData.data,
            });

            return ecardData;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const fetchHospitalData = (payload) => {
    return async (dispatch) => {
        try {
            const hospitalData = await APICall('get', payload, APIEndpoint.hospital);

            dispatch({
                type: HOSPITAL_DATA,
                payload: hospitalData.data,
            });

            return hospitalData;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const fetchAppointmentData = (payload) => {
    return async (dispatch) => {
        try {
            const appointmentData = await APICall('get', payload, APIEndpoint.appointment);

            dispatch({
                type: APPOINTMENT_DATA,
                payload: appointmentData.data,
            });

            return appointmentData;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const exportAppointmentData = () => {
    return async (dispatch) => {
        try {
            const exportData = await APICall('get', {}, `${APIEndpoint.appointment}/export-data`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const url = window.URL.createObjectURL(exportData);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'appointment_data.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

        } catch (error) {
            return Promise.reject();
        }
    };
};

export const bookAppointment = (appointmentData) => {
    return async (dispatch) => {
        try {
            const response = await APICall('post', appointmentData, `${APIEndpoint.appointment}/add`);

            dispatch({
                type: BOOK_APPOINTMENT,
                payload: response,
            });

            return response;
        } catch (error) {
            return Promise.reject();
        }
    };
};



export const fetchClaimsData = (payload) => {
    return async (dispatch) => {
        try {
            const claimsData = await APICall('get', payload, APIEndpoint.claims);

            dispatch({
                type: CLAIMS_DATA,
                payload: claimsData,
            });

            return claimsData;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const deleteClaimsData = (claimId) => {
    return async (dispatch) => {
        try {
            const response = await APICall('delete', null, `${APIEndpoint.claims}/delete/${claimId}`);

            dispatch({
                type: 'DELETE_CLAIM',
                payload: claimId,
            });

            return response;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const addClaimsData = (claimsData) => {
    return async (dispatch) => {
        try {
            const response = await APICall('post', claimsData, `${APIEndpoint.claims}/add`);

            dispatch({
                type: ADD_CLAIM,
                payload: response,
            });

            return response;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const editClaimsData = (claimId, payload) => {
    return async (dispatch) => {
        try {
            const response = await APICall('put', payload, `${APIEndpoint.claims}/edit/${claimId}`);

            dispatch({
                type: ADD_CLAIM,
                payload: response,
            });

            return response;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const exportClaimsData = () => {
    return async (dispatch) => {
        try {
            const claimsExport = await APICall('get', {}, `${APIEndpoint.claims}/export-data`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const url = window.URL.createObjectURL(claimsExport);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'claims_data.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

        } catch (error) {
            return Promise.reject();
        }
    };
};



export const fetchContactData = (payload) => {
    return async (dispatch) => {
        try {
            const contactData = await APICall('get', payload, APIEndpoint.contact);

            dispatch({
                type: CONTACT_DATA,
                payload: contactData.data,
            });

            return contactData;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const fetchCountries = (payload) => {
    return async (dispatch) => {
        try {
            const countriesData = await APICall('get', payload, APIEndpoint.countries);

            dispatch({
                type: COUNTRIES_DATA,
                payload: countriesData.data,
            });

            return countriesData;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const fetchStates = (countryId, payload) => {
    return async (dispatch) => {
        try {
            const statesData = await APICall('get', payload, `${APIEndpoint.states}/${countryId}`);

            dispatch({
                type: COUNTRIES_DATA,
                payload: statesData.data,
            });

            return statesData;
        } catch (error) {
            return Promise.reject();
        }
    };
};

export const fetchCity = (stateId, payload) => {
    return async (dispatch) => {
        try {
            const cityData = await APICall('get', payload, `${APIEndpoint.city}/${stateId}`);

            dispatch({
                type: COUNTRIES_DATA,
                payload: cityData.data,
            });

            return cityData;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const fetchProfileData = (payload) => {
    return async (dispatch) => {
        try {
            const profileData = await APICall('get', payload, `${APIEndpoint.profile}/profiledetailsview`);

            dispatch({
                type: PROFILE_DATA,
                payload: profileData.data,
            });

            return profileData;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const changeProfileData = (payload) => {
    return async (dispatch) => {
        try {
            const response = await APICall('post', payload, `${APIEndpoint.profile}/profiledetails`);

            dispatch({
                type: PROFILE_DATA,
                payload: response.data,
            });

            return response;
        } catch (error) {
            return Promise.reject();
        }
    };
};


export const changePassword = (payload) => {
    return async (dispatch) => {
        try {
            const response = await APICall('post', payload, `${APIEndpoint.profile}/password`);

            dispatch({
                type: PASSWORD_DATA,
                payload: response.data,
            });

            return response;
        } catch (error) {
            return Promise.reject();
        }
    };
};