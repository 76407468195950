import React, { useState, useEffect } from "react";
import { SideBar } from "../../../components/SideNavigation/SideNavigation";
import { Header } from "../../../components/Header/Header";
import PageHeader from "../../../components/Reusable/PageHeader/PageHeader";
import ReusableTable2 from "../../../components/Reusable/ReuseTable/ReuseTable2";
import ReusableForm from "../../../components/Reusable/ReuseFilter/ReuseFilter";
import { LoadingBtn } from "../../../components//Action/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointmentData, exportAppointmentData } from '../../../module/User/UserAction';
import { formatDateTime } from '../../../utility/CommonConstant';
import { EmployeeData } from '../../../utility/EmployeeData';

export const ViewAppointment = () => {

    const dispatch = useDispatch();
    const { appointmentData } = EmployeeData();
    // const appointmentData = useSelector(state => state.appointmentData);
    const [filteredData, setFilteredData] = useState(appointmentData || []);
    const [filterValues, setFilterValues] = useState({
        patientName: '',
        email: '',
        appointmentId: '',
        diagnosticCenter: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     if (!appointmentData) {
    //         dispatch(fetchAppointmentData());
    //     } else {
    //         setFilteredData(appointmentData);
    //     }
    // }, [dispatch, appointmentData]);
    useEffect(() => {
        if (appointmentData) {
            setFilteredData(appointmentData);
        } else { }
    }, [appointmentData]);

    const handleFilter = () => {
        const filtered = appointmentData.filter(item => {
            return (
                (!filterValues.patientName || item.beneficiary_id?.beneficiary_name?.toLowerCase().includes(filterValues.patientName.toLowerCase())) &&
                (!filterValues.email || item.email?.toLowerCase().includes(filterValues.email.toLowerCase())) &&
                (!filterValues.appointmentId || item.beneficiary_id?.medi_assist_id?.toLowerCase().includes(filterValues.appointmentId.toLowerCase())) &&
                (!filterValues.diagnosticCenter || item.hospital_id?.name?.toLowerCase().includes(filterValues.diagnosticCenter.toLowerCase()))
            );
        });
        setFilteredData(filtered);
    };

    const handleReset = () => {
        setFilteredData(appointmentData);
    };

    const handleExportClick = async () => {
        setIsLoading(true);
        try {
            await dispatch(exportAppointmentData());
        } catch (error) { }
        setIsLoading(false);
    };

    const appointmentHeaders = [
        'Patient Name', 'Email', 'Relationship', 'Appointment Id', 'Appointment Datetime', 'Diagnostic Center', 'City'
    ];

    const appointmentTableData = filteredData.map(item => [
        item?.beneficiary_id?.beneficiary_name ?? 'N/A',
        item?.email ?? 'N/A',
        item?.beneficiary_id?.relation ?? 'N/A',
        item?.beneficiary_id?.medi_assist_id ?? 'N/A',
        formatDateTime(item?.createdAt ?? 'N/A'),
        item?.hospital_id?.name ?? '0',
        item?.city?.name ?? '0',
    ]);

    const appointmentFields = [
        { id: 'patientName', name: 'patientName', label: 'Patient Name', type: 'text', placeholder: 'Enter Patient Name' },
        { id: 'email', name: 'email', label: 'Email', type: 'text', placeholder: 'Enter Email' },
        { id: 'appointmentId', name: 'appointmentId', label: 'Appointment Id', type: 'text', placeholder: 'Enter Appointment Id' },
        { id: 'diagnosticCenter', name: 'diagnosticCenter', label: 'Diagnostic Center', type: 'text', placeholder: 'Enter Diagnostic Center' },
    ];

    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">
                            <PageHeader title="Appointment List" pageName="Appointment List" />

                            <ReusableForm
                                fields={appointmentFields}
                                onReset={handleReset}
                                onSubmit={handleFilter}
                                onChange={(e) => setFilterValues({
                                    ...filterValues,
                                    [e.target.name]: e.target.value
                                })}
                            />

                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                                        <h4 className="card-title">Appointment List</h4>
                                        <div>
                                            <button className="btn btn-success py-8 rounded-2 me-2"
                                                onClick={handleExportClick} disabled={isLoading}>
                                                {isLoading ? <LoadingBtn /> : <><i className="fa-solid fa-upload me-1"></i>Export</>}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="table-responsive mt-3">
                                        <ReusableTable2
                                            headers={appointmentHeaders}
                                            data={appointmentTableData}
                                            noDataText="No Data Found"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
