import React, { useState, useEffect } from 'react';
import { SideBar } from "../../../components/SideNavigation/SideNavigation";
import { Header } from "../../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { ClaimTab1 } from './ClaimTab1';
import { ClaimTab2 } from './ClaimTab2';
import { ClaimTab3 } from './ClaimTab3';
import { Message } from "../../../components/AlertMessage/Message";
import { LoadingBtn } from "../../../components//Action/Loading";
import PageHeader from "../../../components/Reusable/PageHeader/PageHeader";
import { useDispatch, useSelector } from 'react-redux';
import { addClaimsData, fetchClaimsData, fetchBeneficiaryData, fetchPolicyData } from '../../../module/User/UserAction';


export const NewClaim = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const beneficiaryData = useSelector(state => state.beneficiaryData);
    const policyData = useSelector(state => state.policyData);

    useEffect(() => {
        if (!beneficiaryData && !policyData) {
            dispatch(fetchBeneficiaryData());
            dispatch(fetchPolicyData());
        } else { }
    }, [dispatch, beneficiaryData, policyData]);

    const [patientId, setPatientId] = useState('');
    const clientId = policyData?.[0]?.employee_id?.corporate_client_id?._id ?? '0';
    const empId = beneficiaryData?.[0]?.employee_id ?? '0';

    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        patientName: '',
        startDate: '',
        endDate: '',
        bankHolderName: '',
        ifsc: '',
        accountNumber: '',
        bankDetails: '',
    });

    const [expenses, setExpenses] = useState([{ billNo: '', billAmount: '', billType: '', date: '' }]);

    const [claimTab2Data, setClaimTab2Data] = useState({
        shortDescription: '',
        doctorName: '',
        clinicName: '',
        clinicPincode: '',
        totalAmountClaimed: '',
    });

    const [files, setFiles] = useState({
        consultation: null,
        receipt: null,
        report: null,
        prescriptions: null,
    });
    const handleFileChange = (newFiles) => {
        setFiles(newFiles);
    };


    const [activeTab, setActiveTab] = useState('userBankDetails');
    const [tab1Completed, setTab1Completed] = useState(false);
    const [tab2Completed, setTab2Completed] = useState(false);
    const [tab1Touched, setTab1Touched] = useState(false);
    const [tab2Touched, setTab2Touched] = useState(false);
    const [declarationChecked, setDeclarationChecked] = useState(false);
    const [showDeclarationError, setShowDeclarationError] = useState(false);

    const handleTabChange = (tab) => {
        if (tab === 'claimDetails' && !tab1Completed) return;
        if (tab === 'declaration' && !tab2Completed) return;
        setActiveTab(tab);

        if (tab === 'claimDetails') setTab1Touched(true);
        if (tab === 'declaration') setTab2Touched(true);
    };

    const handleNextTab = () => {
        if (activeTab === 'userBankDetails') {
            setTab1Touched(true);
            if (tab1Completed) {
                setActiveTab('claimDetails');
            }
        } else if (activeTab === 'claimDetails') {
            setTab2Touched(true);
            if (tab2Completed) {
                setActiveTab('declaration');
            }
        }
    };

    const handlePreviousTab = () => {
        if (activeTab === 'declaration') {
            setActiveTab('claimDetails');
        } else if (activeTab === 'claimDetails') {
            setActiveTab('userBankDetails');
        }
    };

    const handleCheckboxChange = (checkbox) => {
        if (checkbox.id === 'termsAndConditions') {
            setDeclarationChecked(checkbox.checked);
            if (checkbox.checked) {
                setShowDeclarationError(false);
            }
        }
    };




    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!declarationChecked) {
            setShowDeclarationError(true);
            return;
        }
        setShowDeclarationError(false);

        const claimsData = new FormData();

        setIsLoading(true);

        claimsData.append('client_id', clientId);
        claimsData.append('employee_id', empId);
        claimsData.append('patient_id', patientId);
        claimsData.append('treatment_start_date', formData.startDate);
        claimsData.append('treatment_end_date', formData.endDate);
        claimsData.append('nature_of_illness', claimTab2Data.shortDescription);
        claimsData.append('doctor_name', claimTab2Data.doctorName);
        claimsData.append('clinic_name', claimTab2Data.clinicName);
        claimsData.append('clinic_pincode', claimTab2Data.clinicPincode);
        claimsData.append('total_amount_claimed', claimTab2Data.totalAmountClaimed);
        claimsData.append('status', "1");

        claimsData.append('consultation', files.consultation);
        claimsData.append('investigation_bill', files.receipt);
        claimsData.append('investigation_reports', files.report);
        claimsData.append('prescriptions', files.prescriptions);

        claimsData.append('ifsc_code', formData.ifsc);
        claimsData.append('bank_holder_name', formData.bankHolderName);
        claimsData.append('account_number', formData.accountNumber);
        claimsData.append('bank_details', formData.bankDetails);

        claimsData.append('relation_with_employee', formData.relation);
        claimsData.append('gender', formData.gender);
        claimsData.append('patient_age', formData.age);

        const claimsBillsArray = expenses.map(expense => ({
            bill_no: expense.billNo,
            bill_amount: expense.billAmount,
            bill_type: expense.billType,
            bill_date: expense.date,
        }));

        claimsData.append('bill_details', JSON.stringify(claimsBillsArray));

        try {
            await dispatch(addClaimsData(claimsData));
            Message("Claim Added successfully!", "", "success").then(() => {
                dispatch(fetchClaimsData());
                navigate('/all-claims');
            });
        } catch (error) {
            Message('Try Again!', "Failed to Add claim", "error");
        }

        setIsLoading(false);
    };


    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">

                            <PageHeader
                                title="New Claim"
                                pageName="New Claim"
                            />

                            <div className="card p-3">
                                <div className="card-body wizard-content claim-card">
                                    <ul className="nav nav-tabs mb-4 d-flex justify-content-between">
                                        <li className="nav-item d-flex flex-column justify-content-center align-items-center cursor-pointer position-relative">
                                            <a
                                                className={`nav-link nav-link2 rounded-circle ${activeTab === 'userBankDetails' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('userBankDetails')}
                                            >
                                                <div className='tab-count'>1</div>
                                            </a>
                                            <span className={`connector ${activeTab === 'claimDetails' || activeTab === 'declaration' ? 'completed' : ''}`}></span>
                                            <div className="tab-label">User and Bank Details</div>
                                        </li>
                                        <li className="nav-item d-flex flex-column justify-content-center align-items-center cursor-pointer position-relative">
                                            <a
                                                className={`nav-link nav-link2 rounded-circle ${activeTab === 'claimDetails' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('claimDetails')}
                                            >
                                                <div className='tab-count'>2</div>
                                            </a>
                                            <span className={`connector ${activeTab === 'declaration' ? 'completed' : ''}`}></span>
                                            <div className="tab-label">Claim Details</div>
                                        </li>
                                        <li className="nav-item d-flex flex-column justify-content-center align-items-center cursor-pointer position-relative">
                                            <a
                                                className={`nav-link nav-link2 rounded-circle ${activeTab === 'declaration' ? 'active' : ''}`}
                                                onClick={() => handleTabChange('declaration')}
                                            >
                                                <div className='tab-count'>3</div>
                                            </a>
                                            <div className="tab-label">Declaration and Claim Submission</div>
                                        </li>
                                    </ul>

                                    <div className="tab-content pt-2">
                                        {activeTab === 'userBankDetails' && (
                                            <ClaimTab1
                                                formData={formData}
                                                setFormData={setFormData}
                                                setPatientId={setPatientId}
                                                setTab1Completed={setTab1Completed}
                                                isTabTouched={tab1Touched}
                                            />
                                        )}

                                        {activeTab === 'claimDetails' && (
                                            <ClaimTab2
                                                formData={claimTab2Data}
                                                setFormData={setClaimTab2Data}
                                                setTab2Completed={setTab2Completed}
                                                expenses={expenses}
                                                setExpenses={setExpenses}
                                                isTabTouched={tab2Touched}
                                            />
                                        )}

                                        {activeTab === 'declaration' && (
                                            <ClaimTab3
                                                showError={showDeclarationError}
                                                declarationChecked={declarationChecked}
                                                onFileChange={handleFileChange}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />
                                        )}
                                    </div>

                                    <div className="tab-navigation-buttons d-flex justify-content-end gap-3 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-pre"
                                            onClick={handlePreviousTab}
                                            disabled={activeTab === 'userBankDetails'}
                                        >
                                            Previous
                                        </button>
                                        {activeTab === 'declaration' ? (
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-primary2"
                                                onClick={handleSubmit}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <LoadingBtn /> : 'Submit'}
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-primary2"
                                                onClick={handleNextTab}
                                            >
                                                Next
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
