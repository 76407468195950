import React, { useState } from 'react';
import { Message } from "../../../components/AlertMessage/Message";

export const ClaimTab3 = ({ showError, handleCheckboxChange, declarationChecked, onFileChange }) => {

    const [files, setFiles] = useState({
        consultation: null,
        receipt: null,
        report: null,
        prescriptions: null,
    });

    const toggleImageInput = (checkbox, imageInputId, type) => {
        const imageInput = document.getElementById(imageInputId);
        if (checkbox.checked) {
            imageInput.style.display = 'block';
        } else {
            imageInput.style.display = 'none';
            setFiles((prev) => ({ ...prev, [type]: null }));
            onFileChange(files);
        }
    };

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1048576) {
                Message("File size should not exceed 1MB.", "", "warning");
                setFiles(null);
                event.target.value = '';
            } else {
                setFiles((prev) => ({ ...prev, [type]: file }));
                onFileChange({ ...files, [type]: file });
            }
        }
    };


    return (
        <>
            <div className="tab-pane fade show active">
                <section>
                    <div className="row d-flex gap-1">

                        <h5 className="card-title mb-3">Claim Document Checklist</h5>
                        <div className="col-md-12 claim_document">
                            <div className="col-md-4">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox" id="checkbox1" value="option1"
                                    onChange={(e) => toggleImageInput(e.target, 'imageInput1', 'prescriptions')}
                                />
                                <label className="form-check-label" htmlFor="checkbox1">Prescriptions/ Consultation for the ailment</label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput1"
                                    className="form-control mt-2"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, 'prescriptions')}
                                    accept="image/*"
                                />
                            </div>
                        </div>

                        <div className="col-md-12 claim_document">
                            <div className="col-md-4">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox" id="checkbox2" value="option1"
                                    onChange={(e) => toggleImageInput(e.target, 'imageInput2', 'receipt')}
                                />
                                <label className="form-check-label" htmlFor="checkbox2">Consultation bill/ Receipt</label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput2"
                                    className="form-control mt-2"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, 'receipt')}
                                    accept="image/*"
                                />
                            </div>
                        </div>

                        <div className="col-md-12 claim_document">
                            <div className="col-md-4">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox" id="checkbox3" value="option1"
                                    onChange={(e) => toggleImageInput(e.target, 'imageInput3', 'report')}
                                />
                                <label className="form-check-label" htmlFor="checkbox3">Investigation bill/receipt</label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput3"
                                    className="form-control mt-2"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, 'report')}
                                    accept="image/*"
                                />
                            </div>
                        </div>

                        <div className="col-md-12 mb-5 claim_document">
                            <div className="col-md-4">
                                <input
                                    className="form-check-input success check-outline outline-success me-3"
                                    type="checkbox" id="checkbox4" value="option1"
                                    onChange={(e) => toggleImageInput(e.target, 'imageInput4', 'consultation')}
                                />
                                <label className="form-check-label" htmlFor="checkbox4">Investigation reports</label>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="file"
                                    id="imageInput4"
                                    className="form-control mt-2"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, 'consultation')}
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>

                    <form action="#" className="tab-wizard wizard-circle">
                        <div className="row">
                            <h5 className="card-title mb-3">Declaration</h5>
                            <div className="col-md-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input success check-outline outline-success"
                                        type="checkbox"
                                        id="termsAndConditions"
                                        checked={declarationChecked}
                                        onChange={(e) => handleCheckboxChange(e.target)}
                                    />
                                    <label className="form-check-label" htmlFor="termsAndConditions">
                                        I hereby declare that the information furnished in this Claim Form is true & correct to the best of my knowledge & belief. If I have made any false or untrue statement, suppression or concealment of any material fact, my right to claim reimbursement shall be forfeited. I also consent & authorize TPA or insurance company to seek necessary medical information from any hospital / Medical Practitioner who has attended on the person against whom this claim is made. I hereby declare that I have included all the Bills / receipts for the purpose of this claim & that I will not be making any supplementary claim except the Post - hospitalisation claim, if any.
                                    </label>
                                    {showError && (
                                        <div className="text-danger mt-2">This checkbox is required.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>

        </>
    );
};
