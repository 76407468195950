import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../../components/SideNavigation/SideNavigation";
import { Header } from "../../../components/Header/Header";
import { DeleteButton } from "../../../components/Action/DeleteButton";
import PageHeader from "../../../components/Reusable/PageHeader/PageHeader";
import ReusableTable2 from "../../../components/Reusable/ReuseTable/ReuseTable2";
import ReusableForm from "../../../components/Reusable/ReuseFilter/ReuseFilter";
import { useDispatch, useSelector } from 'react-redux';
import { Message } from "../../../components/AlertMessage/Message";
import { LoadingBtn } from "../../../components//Action/Loading";
import { fetchClaimsData, exportClaimsData } from '../../../module/User/UserAction';
import { formatDate } from '../../../utility/CommonConstant';
import { EmployeeData } from '../../../utility/EmployeeData';

export const AllClaims = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { claimsData } = EmployeeData();

    // const claimsData = useSelector(state => state.claimsData);

    // useEffect(() => {
    //     setFilteredData(claimsData?.data || []);
    //     if (!claimsData) {
    //         dispatch(fetchClaimsData());
    //     } else {
    //         setFilteredData(claimsData.data);
    //     }
    // }, [dispatch, claimsData]);
    useEffect(() => {
        if (claimsData) {
            setFilteredData(claimsData?.data || []);
        } else { }
    }, [claimsData]);

    const totalClaims = claimsData?.totalItems ?? '0';
    const pendingClaims = claimsData?.pending ?? '0';
    const activeClaims = claimsData?.active ?? '0';
    const declineClaims = claimsData?.decline ?? '0';

    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(claimsData?.data || []);
    const [filterValues, setFilterValues] = useState({
        patientName: '',
        claimedAmount: '',
        claimedDate: '',
        status: ''
    });

    const allClaimsHeaders = [
        'Tracking no', 'Claim Registered on', 'Patient Name', 'Relation', 'Claimed Amount', 'Status', 'Action'
    ];

    const claimsTableData = filteredData?.map(item => [
        item?.tracking_number ?? 'N/A',
        formatDate(item?.createdAt ?? 'N/A'),
        item?.patient_id?.beneficiary_name ?? 'N/A',
        item?.relation_with_employee ?? 'N/A',
        item?.total_amount_claimed ?? 'N/A',
        item?.status === "1" ? (
            <span className="badge bg-success-subtle text-success">Active</span>
        ) : item?.status === "2" ? (
            <span className="badge bg-warning-subtle text-warning">Pending</span>
        ) : item?.status === "3" ? (
            <span className="badge bg-danger-subtle text-danger">Decline</span>
        ) : 'N/A',
        <>
            <a href="#"
                onClick={(e) => {
                    e.preventDefault();
                    navigate("/claim-edit", { state: { claimData: item } });
                }}>
                <i className="ti ti-edit me-3"></i>
            </a>
            <DeleteButton claimId={item._id} />
        </>
    ]) || [];

    const handleFilter = () => {
        const filtered = claimsData?.data.filter(item => {
            return (
                (!filterValues.patientName || item?.patient_id?.beneficiary_name?.toLowerCase().includes(filterValues.patientName.toLowerCase())) &&
                (!filterValues.claimedAmount || item?.total_amount_claimed?.toString().includes(filterValues.claimedAmount)) &&
                (!filterValues.claimedDate || formatDate(item?.createdAt).includes(filterValues.claimedDate)) &&
                (!filterValues.status || item?.status === (filterValues.status === 'Active' ? '1' : filterValues.status === 'Pending' ? '2' : '3'))
            );
        }) || [];
        setFilteredData(filtered);
    };

    const handleReset = () => {
        setFilteredData(claimsData?.data || []);
    };


    const handleExportClick = async () => {
        setIsLoading(true);
        try { await dispatch(exportClaimsData()); }
        catch (error) { Message('Try Again!', "Failed to Export claim", "error"); }
        setIsLoading(false);
    };

    const allClaimsFields = [
        { id: 'patientName', name: 'patientName', label: 'Patient Name', type: 'text', placeholder: 'Enter Patient Name' },
        { id: 'claimedAmount', name: 'claimedAmount', label: 'Claimed Amount', type: 'number', placeholder: 'Enter Claimed Amount' },
        { id: 'claimedDate', name: 'claimedDate', label: 'Claim Registered on', type: 'date', placeholder: 'Enter Claimed Date' },
        {
            id: 'status',
            name: 'status',
            label: 'Status',
            type: 'select',
            placeholder: 'Select Status',
            options: [
                { value: '', label: 'All' },
                { value: 'Active', label: 'Active' },
                { value: 'Pending', label: 'Pending' },
                { value: 'Decline', label: 'Decline' }
            ]
        },
    ];


    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">
                            <PageHeader
                                title="All Claims"
                                pageName="All Claims"
                            />

                            <div className="row">
                                <div className="col-sm-6 col-xl-3">
                                    <div className="card bg-primary-subtle shadow-none">
                                        <div className="card-body p-4 ">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="round rounded text-bg-primary d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-cards fs-10"></i>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0 ms-4 mb-3">Total Claims</h6>
                                                    <h3 className="mb-0 ms-4 fw-semibold fs-7">{totalClaims}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-3">
                                    <div className="card bg-warning-subtle shadow-none">
                                        <div className="card-body p-4 ">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="round rounded text-bg-warning d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-clock-play fs-10"></i>

                                                </div>
                                                <div>
                                                    <h6 className="mb-0 ms-4 mb-3">Total Pending Claims</h6>
                                                    <h3 className="mb-0 ms-4 fw-semibold fs-7">{pendingClaims}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-3">
                                    <div className="card bg-danger-subtle shadow-none">
                                        <div className="card-body p-4 ">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="round rounded text-bg-danger d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-x fs-10"></i>

                                                </div>
                                                <div>
                                                    <h6 className="mb-0 ms-4 mb-3">Total Decline Claims</h6>
                                                    <h3 className="mb-0 ms-4 fw-semibold fs-7">{declineClaims}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-3">
                                    <div className="card bg-success-subtle shadow-none">
                                        <div className="card-body p-4 ">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="round rounded text-bg-success d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-history-toggle fs-10"></i>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0 ms-4 mb-3">Total Active Claims</h6>
                                                    <h3 className="mb-0 ms-4 fw-semibold fs-7">{activeClaims}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ReusableForm
                                fields={allClaimsFields}
                                onReset={handleReset}
                                onSubmit={handleFilter}
                                onChange={(e) => setFilterValues({
                                    ...filterValues,
                                    [e.target.name]: e.target.value
                                })}
                            />
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                                        <h4 className="card-title">All Claims</h4>
                                        <div className="d-flex flex-wrap row-gap-2">
                                            <a href="#" className="btn btn-success py-8 rounded-2 me-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleExportClick();
                                                }}
                                                disabled={isLoading} >
                                                {isLoading ? <LoadingBtn /> : <><i className="fa-solid fa-upload me-1"></i>Export</>}
                                            </a>
                                            <a href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("/new-claim");
                                                }} className="btn btn-primary py-8 rounded-2"> <i
                                                    className="fa-solid fa-plus me-1"></i>
                                                New Claim</a>
                                        </div>
                                    </div>
                                    <p className="card-subtitle mb-3"></p>
                                    <div className="table-responsive">
                                        <ReusableTable2
                                            headers={allClaimsHeaders}
                                            data={claimsTableData}
                                            noDataText="No Data Found"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
