import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBeneficiaryData } from '../../../../module/User/UserAction';

export const ClaimEditTab1 = ({ formData, setFormData, setTab1Completed, isTabTouched, setPatientId }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const { claimData } = location.state || {};
    const beneficiaryData = useSelector(state => state.beneficiaryData);

    useEffect(() => {
        if (!beneficiaryData) {
            dispatch(fetchBeneficiaryData());
        } else { }
    }, [dispatch, beneficiaryData]);

    const defaultValue = claimData?.patient_id ? {
        value: claimData?.patient_id?._id,
        label: claimData?.patient_id?.beneficiary_name,
    } : null;

    const patientOptions = beneficiaryData?.map(beneficiary => ({
        value: beneficiary?._id,
        label: beneficiary?.beneficiary_name || 'N/A',
        relation: beneficiary?.relation || 'N/A',
        gender: beneficiary?.gender || 'N/A',
        age: beneficiary?.age || 'N/A',
    })) || [];

    const handleSelectChange = (option) => {
        setFormData(prevValues => ({
            ...prevValues,
            patientName: option?.value || '',
            relation: option?.relation || '',
            gender: option?.gender || '',
            age: option?.age || ''
        }));
        setPatientId(option?.value || '');
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const [formErrors, setFormErrors] = useState({
        patientName: '',
        relation: '',
        age: '',
        gender: '',
        startDate: '',
        endDate: '',
        bankHolderName: '',
        ifsc: '',
        accountNumber: '',
        bankDetails: ''
    });

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!formData.patientName) {
            errors.patientName = 'Patient Name is required';
            isValid = false;
        }

        if (!formData.startDate) {
            errors.startDate = 'Start Date is required';
            isValid = false;
        }

        if (!formData.endDate) {
            errors.endDate = 'End Date is required';
            isValid = false;
        }

        if (!formData.bankHolderName) {
            errors.bankHolderName = 'Bank Holder Name is required';
            isValid = false;
        }

        if (!formData.ifsc) {
            errors.ifsc = 'IFSC Code is required';
            isValid = false;
        }

        if (!formData.accountNumber) {
            errors.accountNumber = 'Account Number is required';
            isValid = false;
        }

        if (!formData.bankDetails) {
            errors.bankDetails = 'Bank Details are required';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    useEffect(() => {
        if (isTabTouched) {
            const isValid = validateForm();
            setTab1Completed(isValid);
        }
    }, [formData, isTabTouched, setTab1Completed]);

    return (
        <div className="tab-pane fade show active">
            <form action="#" className="tab-wizard wizard-circle">
                <section>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="patientName">Patient Name :</label>
                                <Select
                                    id="patientName"
                                    name="patientName"
                                    options={patientOptions}
                                    placeholder="Select Patient Name"
                                    classNamePrefix="select"
                                    value={formData.patientName
                                        ? patientOptions.find(option => option.value === formData.patientName)
                                        : defaultValue}
                                    onChange={handleSelectChange}
                                    defaultValue={defaultValue}
                                />
                                {formErrors.patientName && isTabTouched && (
                                    <div className="invalid-feedback d-block">
                                        {formErrors.patientName}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="relation">Relationship with employee :</label>
                                <input type="tel" className="form-control" id="relation" name="relation" placeholder="Enter Relationship" value={formData.relation} disabled />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="patientAge">Patient age :</label>
                                <input type="tel" className="form-control" id="patient-age" name="patient-age" placeholder="Enter Patient age" value={formData.age} disabled />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="gen">Gender :</label>
                                <input type="tel" className="form-control" id="gen" name="gen" placeholder="Enter Gender" value={formData.gender} disabled />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="startDate">Treatment start date :</label>
                                <input
                                    type="date"
                                    className={`form-control ${formErrors.startDate && isTabTouched ? 'is-invalid' : ''}`}
                                    id="startDate"
                                    name="startDate"
                                    value={formData.startDate}
                                    onChange={handleInputChange}
                                />
                                {formErrors.startDate && isTabTouched && (
                                    <div className="invalid-feedback">
                                        {formErrors.startDate}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="endDate">Treatment end date :</label>
                                <input
                                    type="date"
                                    className={`form-control ${formErrors.endDate && isTabTouched ? 'is-invalid' : ''}`}
                                    id="endDate"
                                    name="endDate"
                                    value={formData.endDate}
                                    onChange={handleInputChange}
                                />
                                {formErrors.endDate && isTabTouched && (
                                    <div className="invalid-feedback">
                                        {formErrors.endDate}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <hr />
                    <h5 className="card-title mb-3">Bank Details</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="bankHolderName">Bank Holder Name :</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.bankHolderName && isTabTouched ? 'is-invalid' : ''}`}
                                    id="bankHolderName"
                                    name="bankHolderName"
                                    placeholder="Enter Bank Holder Name"
                                    value={formData.bankHolderName}
                                    onChange={handleInputChange}
                                />
                                {formErrors.bankHolderName && isTabTouched && (
                                    <div className="invalid-feedback">
                                        {formErrors.bankHolderName}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="ifsc">IFSC Code :</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.ifsc && isTabTouched ? 'is-invalid' : ''}`}
                                    id="ifsc"
                                    name="ifsc"
                                    placeholder="Enter IFSC Code"
                                    value={formData.ifsc}
                                    onChange={handleInputChange}
                                />
                                {formErrors.ifsc && isTabTouched && (
                                    <div className="invalid-feedback">
                                        {formErrors.ifsc}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="accountNumber">Account Number :</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.accountNumber && isTabTouched ? 'is-invalid' : ''}`}
                                    id="accountNumber"
                                    name="accountNumber"
                                    placeholder="Enter Account Number"
                                    value={formData.accountNumber}
                                    onChange={handleInputChange}
                                />
                                {formErrors.accountNumber && isTabTouched && (
                                    <div className="invalid-feedback">
                                        {formErrors.accountNumber}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="bankDetails">Bank Details :</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.bankDetails && isTabTouched ? 'is-invalid' : ''}`}
                                    id="bankDetails"
                                    name="bankDetails"
                                    placeholder="Enter Bank Details"
                                    value={formData.bankDetails}
                                    onChange={handleInputChange}
                                />
                                {formErrors.bankDetails && isTabTouched && (
                                    <div className="invalid-feedback">
                                        {formErrors.bankDetails}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    );
};
