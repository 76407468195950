import React, { useState, useEffect } from "react";
import { SideBar } from "../../components/SideNavigation/SideNavigation";
import { Header } from "../../components/Header/Header";
import PageHeader from "../../components/Reusable/PageHeader/PageHeader";
import ReusableForm from "../../components/Reusable/ReuseFilter/ReuseFilter";
import ReusableTable2 from "../../components/Reusable/ReuseTable/ReuseTable2";
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalData } from '../../module/User/UserAction';
import { EmployeeData } from '../../utility/EmployeeData';

export const NetworkHospital = () => {
    // const dispatch = useDispatch();
    // const hospitalData = useSelector(state => state.hospitalData);

    const { hospitalData } = EmployeeData();

    const [filter, setFilter] = useState({
        hospitalName: '',
        specialties: '',
        address: '',
    });

    const [filteredData, setFilteredData] = useState([]);

    // useEffect(() => {
    //     if (!hospitalData) {
    //         dispatch(fetchHospitalData());
    //     } else {
    //         setFilteredData(hospitalData);
    //     }
    // }, [dispatch, hospitalData]);
    useEffect(() => {
        if (hospitalData) {
            setFilteredData(hospitalData);
        } else { }
    }, [hospitalData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const handleFilter = (e) => {
        e.preventDefault();
        const { hospitalName, specialties, address } = filter;

        const filtered = hospitalData.filter(item => {
            return (
                item.name.toLowerCase().includes(hospitalName.toLowerCase()) &&
                item.specialties.toLowerCase().includes(specialties.toLowerCase()) &&
                item.address.toLowerCase().includes(address.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    const handleReset = () => {
        setFilteredData(hospitalData);
    };

    const hospitalHeaders = [
        'Hospital Name', 'Email', 'Mobile', 'Address', 'Specialties', 'Details'
    ];

    const hospitalTableData = filteredData.map(item => [
        item?.name ?? 'N/A',
        item?.email ?? 'N/A',
        item?.mobile_no ?? 'N/A',
        item?.address ?? 'N/A',
        item?.specialties ?? 'N/A',
        "-"
    ]) || [];

    const hospitalFields = [
        { id: 'hospitalName', name: 'hospitalName', label: 'Hospital Name', type: 'text', placeholder: 'Enter Hospital Name', value: filter.hospitalName },
        { id: 'specialties', name: 'specialties', label: 'Specialties', type: 'text', placeholder: 'Enter Specialties', value: filter.specialties },
        { id: 'address', name: 'address', label: 'Address', type: 'text', placeholder: 'Enter Address', value: filter.address }
    ];

    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">
                            <PageHeader
                                title="Hospital List"
                                pageName="Hospital List"
                            />
                            <div className="row">
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card bg-primary-subtle shadow-none">
                                        <div className="card-body p-4">
                                            <div className="d-flex align-items-center">
                                                <div className="round rounded text-bg-primary d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-cards fs-10"></i>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0 ms-5 mb-3">Total Hospital</h6>
                                                    <h3 className="mb-0 ms-5 fw-semibold fs-7">{hospitalData ? hospitalData.length : 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card bg-success-subtle shadow-none">
                                        <div className="card-body p-4">
                                            <div className="d-flex align-items-center">
                                                <div className="round rounded text-bg-success d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-nurse fs-10"></i>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0 ms-5 mb-3">Total Doctor</h6>
                                                    <h3 className="mb-0 ms-5 fw-semibold fs-7">5</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card bg-danger-subtle shadow-none">
                                        <div className="card-body p-4">
                                            <div className="d-flex align-items-center">
                                                <div className="round rounded text-bg-danger d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-certificate fs-10"></i>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0 ms-5 mb-3">Total Qualification</h6>
                                                    <h3 className="mb-0 ms-5 fw-semibold fs-7">5</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ReusableForm
                                fields={hospitalFields}
                                onChange={handleChange}
                                onReset={handleReset}
                                onSubmit={handleFilter}
                            />

                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="card-title">Hospital List</h4>
                                    </div>
                                    <p className="card-subtitle mb-3"></p>
                                    <div className="table-responsive">
                                        <ReusableTable2
                                            headers={hospitalHeaders}
                                            data={hospitalTableData}
                                            noDataText="No Data Found"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
