import React, { useState, useEffect } from "react";
import { SideBar } from "../../components/SideNavigation/SideNavigation";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { ProfileDetails } from "./ProfileDetails";
import { ChangePass } from "./ChangePass";
import PageHeader from "../../components/Reusable/PageHeader/PageHeader";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData } from '../../module/User/UserAction';
import { EmployeeData } from '../../utility/EmployeeData';

export const Profile = () => {

    const { profileData } = EmployeeData();
    // const [name, setName] = useState('N/A');
    // const [email, setEmail] = useState('N/A');

    // const dispatch = useDispatch();
    // const profileData = useSelector(state => state.profileData);

    const profileImage = profileData?.profile_image ?? './assets/image/profile_image/user-1.jpg';
    const userName = profileData?.first_name ?? 'N/A';
    const userEmail = profileData?.email ?? 'N/A';

    // useEffect(() => {

    //     if (profileData) {
    //         setName(profileData.first_name || 'N/A');
    //         setEmail(profileData.email || 'N/A');
    //     } else {
    //         dispatch(fetchProfileData());
    //     }
    // }, [dispatch, profileData]);


    const [activeTab, setActiveTab] = useState('profile');
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div id="main-wrapper">
            <SideBar />
            <div className="page-wrapper">
                <Header />
                <div className="body-wrapper">
                    <div className="container-fluid">

                        <PageHeader
                            title="Profile Details"
                            pageName="Profile Details"
                        />

                        <div className="card overflow-hidden">
                            <div className="card-body p-0">
                                <img src="https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/backgrounds/profilebg.jpg" alt="modernize-img" className="img-fluid banner_image_policy" />
                                <div className="row align-items-center">
                                    <div className="col-lg-4 order-lg-1 order-2">

                                    </div>

                                    <div className="col-lg-4 mt-n3 order-lg-2 order-1">
                                        <div className="mt-n5">
                                            <div className="d-flex align-items-center justify-content-center mb-2">
                                                <div className="d-flex align-items-center justify-content-center round-110">
                                                    <div className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden round-100">
                                                        <img src={profileImage} alt="modernize-img" className="w-100 h-100" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <h5 className="mb-0">{userName}</h5>
                                                <p className="mb-0">{userEmail}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 order-last">

                                    </div>
                                </div>

                                <ul className="nav nav-pills user-profile-tab justify-content-end mt-2 bg-primary-subtle rounded-2 rounded-top-0" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link hstack gap-2 rounded-0 py-6 ${activeTab === 'profile' ? 'active' : ''}`}
                                            id="pills-profile-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected={activeTab === 'profile'}
                                            onClick={() => handleTabChange('profile')}
                                        >
                                            <i className="ti ti-user-circle fs-5"></i>
                                            <span className="d-none d-md-block">Profile Details</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link hstack gap-2 rounded-0 py-6 ${activeTab === 'pass' ? 'active' : ''}`}
                                            id="pills-pass-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-pass"
                                            aria-selected={activeTab === 'pass'}
                                            onClick={() => handleTabChange('pass')}
                                        >
                                            <i className="ti ti-lock fs-5"></i>
                                            <span className="d-none d-md-block">Change Password</span>
                                        </button>
                                    </li>
                                </ul>

                            </div>
                        </div>



                        <div className="card-body">
                            {activeTab === 'profile' && <ProfileDetails />}
                            {activeTab === 'pass' && <ChangePass />}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};
