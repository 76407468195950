import React from "react";

export const PolicyTerms = () => {
    return (
        <>

            <div className="card">
                <div className="card-body">
                    <h4 className="card-title mb-3">GMC Policy Terms & Conditions</h4>
                    <hr />
                    <div className="policy-section m-3">
                        <ul style={{ listStyle: 'inherit', lineHeight: "1.5rem" }} className="line-space">
                            <li>Family Floater: 5 Lakhs Per family</li>
                            <li>1+3 (Self + Spouse & 2 dependent Children and Parents)</li>
                            <li>Age Limit 0-90 Years.</li>
                            <li>Pre-existing diseases covered for new and existing members</li>
                            <li>30 days waiting period waiver for new and existing members</li>
                            <li>1st, 2nd, and 4th Year Exclusion waiver</li>
                            <li>9 Months Waiting Period of Maternity Waived for All</li>
                            <li>Maternity is from Day One with sublimit of INR 50000 for Normal & INR 75000 for C-section – for Two living Children’s.</li>
                            <li>Maternity complications in case of life-threatening the limit may be enhanced to full Sum Insured.</li>
                            <li>Well Baby and Well Mother Charges payable within Maternity Limit.</li>
                            <li>Baby coverage from Day One to be included in Family Floater</li>
                            <li>A 30 days pre and 90 days post hospitalization period for cancer, heart attack, brain and Accident-related claims (ON CASE-TO-CASE BASIS)</li>
                            <li>Room Rent: 1% of Sum Insured for Normal and 2% of Sum Insured for ICU (Proportionate Deduction Clause Applicable).</li>
                            <li>Additions and deletions on pro-rata basis w.e.f respective date of Joining subject to sufficient funds</li>
                            <li>Employee Demise Dependents will be covered till the End of the policy.</li>
                            <li>Employee Demise no deduction on medical expenses in case of death during hospitalization, within Sum insured to Max of INR 2,00,000 Policy Limit</li>
                            <li>Oral Chemotherapy covered up to 50% of the Sum Insured for Curative and non-preventive procedures only.</li>
                            <li>Employees/family members who are presently covered in the GMC policy will be allowed to buy individual health policy with the existing insurance company as per prevailing rates.</li>
                            <li>No midterm inclusion of dependents except for newly married spouse and newborn baby</li>
                            <li>Day care procedures covered as per standard policy terms</li>
                            <li>Congenital Internal Diseases covered</li>
                            <li>LGBTQ covered as part of Family Definition</li>
                            <li>Congenital External Diseases covered in case of life-threatening disease.</li>
                            <li>Cataract INR 25000 per Eye, Hernia INR 30000, Hysterectomy INR 30000, and Piles INR 30000</li>
                            <li>Kidney Stone removal – INR 40000, Gallbladder surgery – 40000, Per Knee Replacement/Hip Replacement restricted to 2,00,000.</li>
                            <li>Corporate Buffer of Rs 25 lacs with sub-limit of Rs 3 lacs per family. Restricted to Major Ailments.</li>
                            <li>Animal/Serpent attack payable restricted to INR 5,000 per family with an overall limit of INR 1,00,000.</li>
                            <li>Hospital or Injury Arising out of Terrorism Covered for all</li>
                            <li>Ambulance charges payable INR 1000 per incidence in case of emergency only</li>
                            <li>Ayurveda, Yoga, Unani, Siddi & Homeopathy only in case of hospitalization for treatment in Government hospitals, Covered up to 25%.</li>
                            <li>Lasik Surgery Covered if the power is beyond +/- 5</li>
                            <li>No Claim Intimation, Claim Submission Period 45 Days from the Date of Discharge.</li>
                            <li>No other Internal Capping’s</li>
                            <li>Coverage of mental illness (Treatment /Depression treatment/OPD basis treatment) - Restricted to 15 lacs for policy period, with submit of 50,000/- per Family</li>
                            <li>Continuity Benefit: Employees /Family Members who are presently covered under GMC Policy of TAVANT TECHNOLOGIES INDIA PVT LTD will be allowed to buy individual Health Policy as per the prevailing rates and will be allowed to get continuity benefit (Excl Maternity benefit and No Claim Discount). Confirmation on coverage of the employee and the dependents to be confirmed by the Insured and TPA.</li>
                            <li>Domiciliary coverage - A situation where the Insured Person is in such a state that he/she cannot be moved to a hospital, or the treatment is taken at home if there's a non-availability of room in the hospital within 50KM of radius. The policyholder will be compensated for the medical expenses incurred during the policy period on the written advice of a doctor.</li>
                            <li>Modern Treatment Covered - Upto 50% of Sum Insured</li>
                            <li>ARMD Injection Covered - 25,000/- per claim</li>
                            <li>Peritoneal Dialysis Covered</li>
                            <li>TPA facility for Cashless hospitalization – FHPL</li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-body mb-3">
                    <h4 className="card-title">GMC Exclusions:</h4>
                    <hr />
                    <div className="policy-section m-3">
                        <ul style={{ listStyle: 'number', lineHeight: "1.5rem" }} className="line-space">
                            <li>Cost of Spectacles, contact lenses & hearing aids</li>
                            <li>Dental Treatment or surgery of any kind unless requiring
                                hospitalisation</li>
                            <li>Congenital & related Diseases</li>
                            <li>Intentional Self injury, Against the Law,
                                Smoking/Alcoholism, Drugs and related Hospitalizations</li>
                            <li>Treatment arising from or traceable pregnancy (including voluntary termination of pregnancy) & childbirth (including caesarean section)
                            </li>
                            <li>Naturopathy Treatment, Acupressure, Accupuncture, Magnetic & such other therapies, Ayurvedic treatment unless specifically covered, Non Medical Expenses such as Admission/Registration Fee, Gloves, Disposable Syringes, Needles, Blades, Sanitary Napkins, Infant Feeding Tube, Crepe Bandage, Vaccination charges & other expenses which are essential for the treatment undergone, Diet Charges, Attendant Charges, Laundry, Telephone, Documentation Charges, TV, Luxury Tax etc (These expenses are essentially not connected to the treatment)
                            </li>
                            <li>Any kind of Service charges, surcharges, Admission Fees/Registration Charges levied by the hospital, Circumcision unless necessary for the treatment of an Illness not otherwise excluded in this Section, or required as a result of Accidental Bodily Injury.</li>
                            <li>Vaccination, inoculation, cosmetic treatments (including any complications arising out of or howsoever attributable to any cosmetic treatments or the replacement of an existing breast implant), aesthetic treatments, experimental, investigational or unproven procedures or treatments, devices and pharmacological regimens of any description.</li>
                            <li>The Insured Person ’ s participation in any hazardous activities, including but not limited to scuba diving, motor- racing, parachuting, hang-gliding, rock or mountain climbing, as a member of the armed forces, the paramilitary, the security forces, the fire or ambulance services, lifeboat service, police force and the like whether part time or full time, voluntary or paid.</li>
                        </ul>

                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-body mb-3">
                    <h4 className="card-title">GPA Coverage’s :</h4>
                    {/* <div className="policy-section m-3"> */}
                    <div className=" mt-3">
                        <table className="table table-bordered">

                            <tbody>
                                <tr>
                                    <th>Death</th>
                                    <td>Sum Insured: 60 times Gross Monthly Salary subject to minimum of Rs 1,000,000 (May Vary based on Client to client)</td>
                                </tr>
                                <tr>
                                    <th>PTD : Permanent Total Disablement</th>
                                    <td>Covered as per standard GPA policy wordings</td>
                                </tr>
                                <tr>
                                    <th>PPD: Permanent Partial Disablement</th>
                                    <td>Covered as per standard GPA policy wordings</td>
                                </tr>
                                <tr>
                                    <th>Weekly Benefit/Temporary Total Disablement (TTD)</th>
                                    <td>Sum Insured: 24 Times Gross Monthly Salary subject to maximum of Rs 500,000 <br /> OR <br /> 1% of Weekly Benefit Sum Insured subject to maximum Rs 5,000 per week upto maximum 104 weeks (Subject to max Weekly Benefit Sum Insured)
                                    </td>
                                </tr>
                                <tr>
                                    <th>Medical Reibursement</th>
                                    <td>(a) 40% of admissible claim amount in GPA Benefits as mentioned above (or) <br />
                                        (b) 10% of Sum Insured (or) <br />
                                        (c) Actual Medical Bills incurred (Original Bills mandatory) <br />
                                        Lesser of the above three will be assessed under this extension <br />
                                        NOTE: <br />
                                        (1)Stand Alone Medical Reimbursement is not payable unless there is a Valid claim in GPA Benefit, as this is only an Extension
                                    </td>
                                </tr>
                                <tr>
                                    <th>Terrorism</th>
                                    <td>Covered (To Mention AOA:AOY Limit, if any)</td>
                                </tr>
                                <tr>
                                    <th>Children Educational Fund (Only for Death/100% PTD) in addition to Sum Insured</th>
                                    <td>10% of Capital Sum Insured subject to Maximum of Rs.5,000 per child and is allowed for two children</td>
                                </tr>
                                <tr>
                                    <th>Expenses for Carriage of Dead Body (Only for Death) (in addition to Death Sum Insured)</th>
                                    <td>2% of Capital Sum Insured subject to max Rs 2,500</td>
                                </tr>
                                <tr>
                                    <th>Emergency Ambulance Charges in addition to Capital Sum Insured </th>
                                    <td>Max Rs 1,000 per Insured person per any one accident (Original Bills mandatory)</td>
                                </tr>
                                <tr>
                                    <th>Loss of Employment (Only for 100% PTD) in addition to Sum Insured</th>
                                    <td>1% of the Capital Sum Insured subject to a maximum of Rs. 15,000 in addition to CSI  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* </div> */}
                </div>
            </div>


            <div className="card">
                <div className="card-body mb-3">
                    <h4 className="card-title">GPA General Exclusione:</h4>
                    <hr />
                    <p className="my-3 text-dark">This policy does not provide benefits for any death, disability, expense or loss incurred in result of any Injury attributable directly or indirectly to the following:</p>
                    <div className="policy-section m-3">
                        <ul style={{ listStyle: 'number', lineHeight: "1.5rem" }} className="line-space">
                            <li>Intentionally self-inflicted injury, provoked assault, suicide or any attempt thereat while sane or insane; </li>
                            <li>Ionizing radiation/Radioactive related/Nuclear related/Toxix, explosive or other dangerous properties of any explosive nuclear equipment or any part of that equipment</li>
                            <li>War, invasion, act of foreign enemy, hostilities (whether war be declared or not), civil war, rebellion, revolution, terrorism or terrorist acts (Unless specifically covered), insurrection, mutiny, military or usurped power, seizure, capture, arrests, restraints and detainment of all kings, princes, and people of whatsoever nation condition or quality</li>
                            <li>The Insured Person’s participation in naval, military or air force operations whether in the form of military exercises or war games or actual engagement with the enemy with foreign or domestic</li>
                            <li>Under the influence of alcohol or drugs </li>
                            <li>Violation or attempted violation of the law</li>
                            <li>Any loss sustained whilst engaging in aviation or ballooning, whilst mounting into, dismounting from or traveling in any balloon or aircraft other than as a passenger (fare paying otherwise) in any duly licensed standard type of aircraft anywhere in the world</li>
                            <li>8	Any Disease/Infection/Sickness//AIDS</li>
                            <li>Participating in contests of speed using a motorized vehicle or bicycle and/or hunting and/or skiing and/or skydiving and/or gliding and/or mountaineering and/or winter sports and such related adventures</li>
                            <li>Any loss resulting directly or indirectly from or, contributed or aggravated or prolonged by childbirth or from pregnancy</li>
                            <li>Medical or surgical treatment except as may be necessary solely as a result of Injury/Accident</li>
                        </ul>

                    </div>
                </div>
            </div>
        </>
    );
};
