import React, { useState, useEffect } from "react";
import { Message } from "../../components/AlertMessage/Message";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData, changeProfileData, fetchAllData } from '../../module/User/UserAction';
import { formatDate } from '../../utility/CommonConstant';
import { LoadingBtn } from "../../components//Action/Loading";

export const ProfileDetails = () => {

    const dispatch = useDispatch();
    const profileData = useSelector(state => state.profileData);

    const [profileImage, setProfileImage] = useState(null);
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hrNumber, setHrNumber] = useState('9532145552');
    const [address, setAddress] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (profileData) {
            setName(profileData?.first_name ?? '');
            setEmail(profileData?.email ?? '');
            setDob(formatDate(profileData?.dob) ?? '');
            setPhone(profileData?.mobile ?? '');
            setGender(profileData?.gender ?? '');
            setAddress(profileData?.address ?? '');
        } else {
            dispatch(fetchProfileData());
        }
    }, [dispatch, profileData]);


    const [errors, setErrors] = useState({
        email: '',
        dob: '',
        gender: '',
        name: '',
        phone: '',
        hrNumber: '',
        address: '',
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        switch (id) {
            case 'email':
                setEmail(value);
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    setErrors(prevErrors => ({ ...prevErrors, email: 'Please enter a valid email address.' }));
                } else {
                    setErrors(prevErrors => ({ ...prevErrors, email: '' }));
                }
                break;
            case 'phone':
                if (value.length <= 10) {
                    setPhone(value);
                    if (!/^\d{10}$/.test(value)) {
                        setErrors(prevErrors => ({ ...prevErrors, phone: 'Please enter a valid phone number with 10 digits.' }));
                    } else {
                        setErrors(prevErrors => ({ ...prevErrors, phone: '' }));
                    }
                }
                break;
            case 'dob':
                setDob(value);
                setErrors(prevErrors => ({ ...prevErrors, dob: '' }));
                break;
            case 'gender':
                setGender(value);
                setErrors(prevErrors => ({ ...prevErrors, gender: '' }));
                break;
            case 'name':
                setName(value);
                setErrors(prevErrors => ({ ...prevErrors, name: '' }));
                break;
            case 'hrNumber':
                setHrNumber(value);
                setErrors(prevErrors => ({ ...prevErrors, hrNumber: '' }));
                break;
            case 'address':
                setAddress(value);
                setErrors(prevErrors => ({ ...prevErrors, address: '' }));
                break;
            default:
                break;
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1048576) {
                Message("File size should not exceed 1MB.", "", "warning");
                setProfileImage(null);
                event.target.value = '';
            } else {
                setProfileImage(file);
            }
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            email: '',
            dob: '',
            gender: '',
            name: '',
            phone: '',
            hrNumber: '',
            address: '',
        };

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            newErrors.email = 'Please enter a valid email address.';
            valid = false;
        }

        const phoneRegex = /^\d{10}$/;
        if (!phone || !phoneRegex.test(phone)) {
            newErrors.phone = 'Please enter a valid phone number with 10 digits.';
            valid = false;
        }

        if (!dob) {
            newErrors.dob = 'Date of Birth is required.';
            valid = false;
        }
        if (!gender) {
            newErrors.gender = 'Gender is required.';
            valid = false;
        }
        if (!name) {
            newErrors.name = 'Name is required.';
            valid = false;
        }
        if (!hrNumber) {
            newErrors.hrNumber = 'HR Number is required.';
            valid = false;
        }
        if (!address) {
            newErrors.address = 'Address is required.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (validateForm()) {
            const formData = new FormData();
            formData.append('first_name', name);
            formData.append('middle_name', name);
            formData.append('last_name', name);
            formData.append('email', email);
            formData.append('dob', dob);
            formData.append('gender', gender);
            formData.append('mobile', phone);
            formData.append('address', address);

            if (profileImage) {
                formData.append('profile_image', profileImage);
            }

            try {
                await dispatch(changeProfileData(formData));
                Message("Profile Details Updated successfully!", "", "success");
                dispatch(fetchAllData());
            } catch (error) {
                Message("Try Again!", "Error updating profile!", "error");
            }
        }
        setIsLoading(false);
    };


    const handleReset = () => {
        setProfileImage(null);
        setName(profileData?.first_name ?? '');
        setEmail(profileData?.email ?? '');
        setDob(formatDate(profileData?.dob) ?? '');
        setPhone(profileData?.mobile ?? '');
        setGender(profileData?.gender ?? '');
        setAddress(profileData?.address ?? '');
    };


    return (
        <div className="col-12">
            <div className="card w-100 border position-relative overflow-hidden">
                <div className="card-body p-4">
                    <h4 className="card-title mb-4">Personal Details</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">Profile Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        onChange={handleFileChange}
                                        accept="image/*"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="dob" className="form-label">Date of Birth</label>
                                    <input
                                        type="date"
                                        className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                                        id="dob"
                                        value={dob}
                                        onChange={handleInputChange}
                                    />
                                    {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="gender" className="form-label">Gender</label>
                                    <select
                                        className={`form-select ${errors.gender ? 'is-invalid' : ''}`}
                                        id="gender"
                                        value={gender}
                                        onChange={handleInputChange}
                                    >
                                        <option defaultValue="" disabled>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    {errors.gender && <div className="invalid-feedback">{errors.gender}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                        id="name"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange={handleInputChange}
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input
                                        type="number"
                                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                        id="phone"
                                        placeholder="9876543210"
                                        value={phone}
                                        onChange={handleInputChange}
                                    />
                                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="hrNumber" className="form-label">HR Number</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.hrNumber ? 'is-invalid' : ''}`}
                                        id="hrNumber"
                                        placeholder="Enter HR Number"
                                        value={hrNumber}
                                        onChange={handleInputChange}
                                    />
                                    {errors.hrNumber && <div className="invalid-feedback">{errors.hrNumber}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                        id="address"
                                        placeholder="814 Howard Street, 120065, India"
                                        value={address}
                                        onChange={handleInputChange}
                                    />
                                    {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                                </div>
                            </div>
                            <div className="col-12 d-flex align-items-center justify-content-end mt-4 gap-6">
                                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? <LoadingBtn /> : 'Update Profile'}
                                </button>
                                <button type="button" className="btn bg-danger-subtle text-danger" onClick={handleReset}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
