import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import userReducer from "./User/UserReducer";

const store = createStore(userReducer, applyMiddleware(thunk));

// store.subscribe(() => {
//     console.log('Current store state:', store.getState());
// });

export default store;
