import React, { useState, useEffect } from "react";
import { Message } from "../../components/AlertMessage/Message";
import { useDispatch } from 'react-redux';
import { editBeneficiaryData, fetchAllData } from '../../module/User/UserAction';
import { LoadingBtn } from "../../components//Action/Loading";

export const EditBeneficiary = ({ selectedBeneficiary }) => {

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        beneficiary_name: '',
        medi_assist_id: '',
        dob: '',
        relation: '',
        gender: '',

        employee_id: '',
        salse_policy_id: '',

        balancesi: '',
        status: ''
    });
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    useEffect(() => {
        if (selectedBeneficiary) {
            setFormData({
                beneficiary_name: selectedBeneficiary.beneficiary_name || '',
                medi_assist_id: selectedBeneficiary.medi_assist_id || '',
                dob: formatDate(selectedBeneficiary.dob),
                relation: selectedBeneficiary.relation || '',
                gender: selectedBeneficiary.gender || '',

                employee_id: selectedBeneficiary.employee_id || '',
                salse_policy_id: selectedBeneficiary.salse_policy_id || '',

                balancesi: selectedBeneficiary.balancesi || '',
                status: selectedBeneficiary.status || ''
            });
        }
    }, [selectedBeneficiary]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        setIsLoading(true);

        const benId = selectedBeneficiary._id;
        const payload = {
            employee_id: formData.employee_id,
            salse_policy_id: formData.salse_policy_id,
            beneficiary_name: formData.beneficiary_name,
            medi_assist_id: formData.medi_assist_id,
            dob: formData.dob,
            relation: formData.relation,
            gender: formData.gender,
            balancesi: formData.balancesi,
            status: formData.status
        };

        dispatch(editBeneficiaryData(benId, payload))
            .then(response => {
                Message("Beneficiary details edited successfully!", "", "success").then(() => {
                    document.querySelector('#beneficiary .btn-close').click();
                });
                dispatch(fetchAllData());
            })
            .catch(error => {
                Message('Try Again!', "Failed to update beneficiary", "error");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    return (
        <div className="modal fade" id="beneficiary" tabIndex="-1" aria-labelledby="eventModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="eventModalLabel">Edit Beneficiary</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 mt-3">
                                    <label className="form-label">Beneficiary Name</label>
                                    <input
                                        name="beneficiary_name"
                                        type="text"
                                        className={`form-control ${validated && !formData.beneficiary_name ? 'is-invalid' : ''}`}
                                        placeholder="Enter Beneficiary Name"
                                        value={formData.beneficiary_name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter the beneficiary name.</div>
                                </div>

                                <div className="col-md-6 mt-3">
                                    <label className="form-label">Medi Assist Id</label>
                                    <input
                                        name="medi_assist_id"
                                        type="text"
                                        className={`form-control ${validated && !formData.medi_assist_id ? 'is-invalid' : ''}`}
                                        placeholder="Enter Medi Assist Id"
                                        value={formData.medi_assist_id}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter the Medi Assist Id.</div>
                                </div>

                                <div className="col-md-6 mt-3">
                                    <label className="form-label">Dob</label>
                                    <input
                                        name="dob"
                                        type="date"
                                        className={`form-control ${validated && !formData.dob ? 'is-invalid' : ''}`}
                                        value={formData.dob}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter the date of birth.</div>
                                </div>

                                <div className="col-md-6 mt-3">
                                    <label className="form-label">Relation</label>
                                    <input
                                        name="relation"
                                        type="text"
                                        className={`form-control ${validated && !formData.relation ? 'is-invalid' : ''}`}
                                        placeholder="Enter Relation"
                                        value={formData.relation}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter the relation.</div>
                                </div>

                                <div className="col-md-6 mt-3">
                                    <label className="form-label">Gender</label>
                                    <select
                                        name="gender"
                                        className={`form-select ${validated && !formData.gender ? 'is-invalid' : ''}`}
                                        value={formData.gender}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option defaultValue="" disabled>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    <div className="invalid-feedback">Please select a gender.</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>{isLoading ? <LoadingBtn /> : 'Update Beneficiary'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
