import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export const ClaimEditTab2 = ({ formData, setFormData, setTab2Completed, expenses, setExpenses, isTabTouched }) => {

    const [validationErrors, setValidationErrors] = useState({});
    const [expenseValidationErrors, setExpenseValidationErrors] = useState({});

    useEffect(() => {
        if (isTabTouched) {
            validateForm();
        }
    }, [formData, expenses, isTabTouched]);

    const validateForm = () => {
        const requiredFields = [
            'shortDescription',
            'doctorName',
            'clinicName',
            'clinicPincode',
        ];
        const errors = requiredFields.reduce((acc, field) => {
            if (!formData[field] || formData[field] === '') {
                acc[field] = 'This field is required.';
            }
            return acc;
        }, {});

        setValidationErrors(errors);
        const expenseErrors = expenses.reduce((acc, expense, index) => {
            const expenseErrors = {};
            if (!expense.billNo) expenseErrors.billNo = 'Bill No is required.';
            if (!expense.billAmount) expenseErrors.billAmount = 'Bill Amount is required.';
            if (!expense.billType) expenseErrors.billType = 'Bill Type is required.';
            if (!expense.date) expenseErrors.date = 'Bill date is required.';

            if (Object.keys(expenseErrors).length > 0) {
                acc[index] = expenseErrors;
            }
            return acc;
        }, {});

        setExpenseValidationErrors(expenseErrors);
        const isValid = Object.keys(errors).length === 0 && Object.keys(expenseErrors).length === 0;
        setTab2Completed(isValid);
    };

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newExpenses = [...expenses];
        newExpenses[index][name] = value;
        setExpenses(newExpenses);
    };

    const addExpense = () => {
        setExpenses([...expenses, { billNo: '', billAmount: '', billType: '', date: '' }]);
    };

    const removeExpense = (index) => {
        const newExpenses = expenses.filter((_, i) => i !== index);
        setExpenses(newExpenses);
    };


    return (
        <div className="tab-pane fade show active">
            <section>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="shortDescription1">Nature of illness/disease/accident :</label>
                            <textarea
                                name="shortDescription"
                                id="shortDescription1"
                                rows="2"
                                className={`form-control ${validationErrors.shortDescription ? 'is-invalid' : ''}`}
                                value={formData.shortDescription}
                                onChange={handleFormChange}
                            ></textarea>
                            {validationErrors.shortDescription && (
                                <div className="invalid-feedback">
                                    {validationErrors.shortDescription}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="doctorName">Doctor/Chemist Name :</label>
                            <textarea
                                name="doctorName"
                                id="doctorName"
                                rows="2"
                                className={`form-control ${validationErrors.doctorName ? 'is-invalid' : ''}`}
                                value={formData.doctorName}
                                onChange={handleFormChange}
                            ></textarea>
                            {validationErrors.doctorName && (
                                <div className="invalid-feedback">
                                    {validationErrors.doctorName}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="clinicName">Clinic Name :</label>
                            <input
                                type="text"
                                className={`form-control ${validationErrors.clinicName ? 'is-invalid' : ''}`}
                                name="clinicName"
                                id="clinicName"
                                placeholder="Enter Clinic Name"
                                value={formData.clinicName}
                                onChange={handleFormChange}
                            />
                            {validationErrors.clinicName && (
                                <div className="invalid-feedback">
                                    {validationErrors.clinicName}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="clinicPincode">Clinic PinCode :</label>
                            <input
                                type="text"
                                className={`form-control ${validationErrors.clinicPincode ? 'is-invalid' : ''}`}
                                name="clinicPincode"
                                id="clinicPincode"
                                placeholder="Enter Clinic PinCode"
                                value={formData.clinicPincode}
                                onChange={handleFormChange}
                            />
                            {validationErrors.clinicPincode && (
                                <div className="invalid-feedback">
                                    {validationErrors.clinicPincode}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="totalAmountClaimed">Total Amount Claimed :</label>
                            <input
                                type="number"
                                className={`form-control ${validationErrors.totalAmountClaimed ? 'is-invalid' : ''}`}
                                id="totalAmountClaimed"
                                name="totalAmountClaimed"
                                placeholder="Enter Total Amount Claimed"
                                value={formData.totalAmountClaimed}
                                onChange={handleFormChange}
                            />
                            {validationErrors.totalAmountClaimed && (
                                <div className="invalid-feedback">
                                    {validationErrors.totalAmountClaimed}
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <hr />
                <h5 className="card-title mb-3">Medical Expenses Breakup</h5>
                {expenses.map((expense, index) => (
                    <div className="row mb-3 d-flex align-items-baseline" key={index}>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <input
                                    type="number"
                                    className={`form-control ${expenseValidationErrors[index]?.billNo ? 'is-invalid' : ''}`}
                                    name="billNo"
                                    value={expense.billNo}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder="Enter Bill No"
                                />
                                {expenseValidationErrors[index]?.billNo && (
                                    <div className="invalid-feedback">
                                        {expenseValidationErrors[index]?.billNo}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <input
                                    type="number"
                                    className={`form-control ${expenseValidationErrors[index]?.billAmount ? 'is-invalid' : ''}`}
                                    name="billAmount"
                                    value={expense.billAmount}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder="Enter Bill Amount"
                                />
                                {expenseValidationErrors[index]?.billAmount && (
                                    <div className="invalid-feedback">
                                        {expenseValidationErrors[index]?.billAmount}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className={`form-control ${expenseValidationErrors[index]?.billType ? 'is-invalid' : ''}`}
                                    name="billType"
                                    value={expense.billType}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder="Enter Bill Type"
                                />
                                {expenseValidationErrors[index]?.billType && (
                                    <div className="invalid-feedback">
                                        {expenseValidationErrors[index]?.billType}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="mb-3">
                                <input
                                    type="date"
                                    className={`form-control ${expenseValidationErrors[index]?.date ? 'is-invalid' : ''}`}
                                    name="date"
                                    value={expense.date}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder="Enter date"
                                />
                                {expenseValidationErrors[index]?.date && (
                                    <div className="invalid-feedback">
                                        {expenseValidationErrors[index]?.date}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-1 d-flex align-items-center">
                            {index === 0 && (
                                <button
                                    type="button"
                                    className="btn btn-success mx-1"
                                    onClick={() => addExpense(index)}
                                >
                                    <i className="ti ti-circle-plus fs-5 d-flex"></i>
                                </button>
                            )}
                            {expenses.length > 1 && index > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-danger mx-1"
                                    onClick={() => removeExpense(index)}
                                >
                                    <i className="ti ti-circle-x fs-5 d-flex"></i>
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
};
