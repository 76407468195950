import React, { useEffect, useState } from "react";
import { SideBar } from "../../components/SideNavigation/SideNavigation";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/Reusable/PageHeader/PageHeader";
import ReusableTable2 from "../../components/Reusable/ReuseTable/ReuseTable2";
import { PolicyTerms } from "../../components/Reusable/PolicyTerms/PolicyTerms";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPolicyData, fetchProfileData } from '../../module/User/UserAction';
import { formatDate, formatCurrency } from '../../utility/CommonConstant';
import { EmployeeData } from '../../utility/EmployeeData';


export const Policy = () => {
    const navigate = useNavigate();
    const { profileData, policyData } = EmployeeData();

    // const dispatch = useDispatch();
    // const policyData = useSelector(state => state.policyData);
    // const profileData = useSelector(state => state.profileData);


    // useEffect(() => {

    //     if (!policyData || !profileData) {
    //         dispatch(fetchPolicyData());
    //         dispatch(fetchProfileData());
    //     }
    //     else {
    //         dispatch(fetchPolicyData());
    //         dispatch(fetchProfileData());
    //     }
    // }, [dispatch, policyData, profileData]);

    const userName = profileData?.first_name ?? 'N/A';
    const userEmail = profileData?.email ?? 'N/A';
    const policy_number = policyData?.[0]?.policy_id?.policy_number ?? 'N/A';
    const policy_type = policyData?.[0]?.policy_id?.policy_type ?? 'N/A.';
    const date_of_joining = policyData?.[0]?.date_of_joining ?? '0';
    const policy_end_date = policyData?.[0]?.policy_end_date ?? '0';
    const employee_id_number = policyData?.[0]?.employee_id?.employee_id_number ?? 'N/A';
    const mobile = policyData?.[0]?.employee_id?.mobile ?? 'N/A';


    const employeePolicyHeaders = [
        'Name', 'Relation', 'Gender', 'Sum Insured', 'Date of Joining', 'Policy End Date', 'No of Days', 'Basic Premium', 'Prorata Premium', 'TGST', 'Premium INCL GST'
    ];

    const employeePolicyData = policyData?.map(item => [
        item?.employee_id?.first_name ?? 'N/A',
        'Self',
        item?.employee_id?.gender ?? 'N/A',
        item?.policy_id?.sum_insured ?? '0',
        formatDate(item?.date_of_joining),
        formatDate(item?.policy_end_date),
        item?.no_of_day ?? '0',
        formatCurrency(item?.basic_premium ?? '0'),
        formatCurrency(item?.prorate_premium ?? '0'),
        formatCurrency(item?.totalPremiumIncludingGST ?? '0'),
        formatCurrency(item?.premium_incl_gst ?? '0')
    ]) || [];


    const BeneficialHeaders = [
        'Name', 'Relation', 'Gender', 'Sum Insured', 'Date of Joining', 'Policy End Date', 'No of Days', 'Basic Premium', 'Prorata Premium', 'TGST', 'Premium INCL GST'
    ];


    const BeneficialData = policyData?.flatMap(item =>
        item?.beneficiaries?.map(beneficiary => [
            beneficiary?.beneficiary_name ?? 'N/A',
            beneficiary?.relation ?? 'N/A',
            beneficiary?.gender ?? 'N/A',
            item?.policy_id?.sum_insured ?? '0',
            formatDate(item?.date_of_joining),
            formatDate(item?.policy_end_date),
            item?.no_of_day ?? '0',
            formatCurrency(item?.basic_premium ?? '0'),
            formatCurrency(beneficiary?.beneficiaryProratePremium ?? '0'),
            formatCurrency(beneficiary?.beneficiary_gst ?? '0'),
            formatCurrency(beneficiary?.beneficiary_premium_incl_gst ?? '0')
        ])
    ) || [];


    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />

                    <div className="body-wrapper">
                        <div className="container-fluid">

                            <PageHeader
                                title="Policy Detail"
                                pageName="Policy Detail"
                            />


                            <div className="card overflow-hidden">
                                <div className="card-body p-0">
                                    <img src="https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/backgrounds/profilebg.jpg"
                                        alt="modernize-img" className="img-fluid banner_image_policy" />
                                    <div className="row align-items-center">
                                        <div className="col-lg-4 order-lg-1 order-2">

                                        </div>
                                        <div className="col-lg-4 mt-n3 order-lg-2 order-1">
                                            <div className="mt-n5 mb-3">
                                                <div className="d-flex align-items-center justify-content-center mb-2">
                                                    <div className="d-flex align-items-center justify-content-center round-110">
                                                        <div
                                                            className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden round-100">
                                                            <img src="./assets/image/profile_image/user-1.jpg"
                                                                alt="modernize-img" className="w-100 h-100" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <h5 className="mb-0">{userName}</h5>
                                                    <p className="mb-0">{userEmail}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 d-flex align-items-stretch">
                                    <div className="card w-100">
                                        <div className="form-horizontal">
                                            <div className="form-body">
                                                <div className="card-body">
                                                    <h5 className="card-title mb-0">Company Provided Insurance</h5>
                                                </div>
                                                <hr className="m-0" />
                                                <div className="card-body d-flex flex-column align-stretch">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Corp Name :</label>
                                                                <div className="col-md-6">
                                                                    <p>Savvvyy</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Policy Type :</label>
                                                                <div className="col-md-6">
                                                                    <p>{policy_type}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Policy No :</label>
                                                                <div className="col-md-6">
                                                                    <p>{policy_number}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Policy From :</label>
                                                                <div className="col-md-6">
                                                                    <p>{formatDate(date_of_joining)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Policy To :</label>
                                                                <div className="col-md-6">
                                                                    <p>{formatDate(policy_end_date)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Employee ID :</label>
                                                                <div className="col-md-6">
                                                                    <p>{employee_id_number}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Email ID :</label>
                                                                <div className="col-md-6">
                                                                    <p>{userEmail}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group row">
                                                                <label className="form-label col-md-6">Mobile No :</label>
                                                                <div className="col-md-6">
                                                                    <p>{mobile}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex mb-2 align-items-center">
                                        <div>
                                            <h4>Employee Policy Details</h4>
                                        </div>

                                    </div>
                                    <div className="table-responsive border rounded-4">

                                        <ReusableTable2
                                            headers={employeePolicyHeaders}
                                            data={employeePolicyData}
                                            noDataText="No Data Found"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex mb-2 align-items-center justify-content-between">
                                        <div>
                                            <h4>Beneficial Details</h4>
                                        </div>
                                        <div>
                                            <a href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("/beneficiary");
                                                }} className="btn btn-primary">
                                                Edit Benefical</a>
                                        </div>

                                    </div>
                                    <div className="table-responsive border rounded-4">

                                        <ReusableTable2
                                            headers={BeneficialHeaders}
                                            data={BeneficialData}
                                            noDataText="No Data Found"
                                        />

                                    </div>
                                </div>
                            </div>


                            <PolicyTerms />


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
