import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';

import { useDispatch, useSelector } from 'react-redux';
import { userLogout, fetchProfileData } from '../../module/User/UserAction';
import { useAuth } from '../../config/AuthContext';
import { EmployeeData } from '../../utility/EmployeeData';

export const SideBar = () => {
    const [expandedMenu, setExpandedMenu] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const isActiveRoute = (route) => location.pathname === route;

    const isParentMenuActive = (menu) => {
        const activeRoutes = {
            claims: ["/all-claims", "/new-claim"],
            wellness: ["/book-appointment", "/view-appointment"],
            cpf: ["/cashless-flow", "/non-cashless-flow"],
        };
        return activeRoutes[menu]?.some((route) => isActiveRoute(route));
    };

    const toggleMenu = (menu) => {
        if (expandedMenu === menu) {
            setExpandedMenu(null);
        } else {
            setExpandedMenu(menu);
        }
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        document.getElementById("main-wrapper").classList.toggle("show-sidebar");
        document.querySelectorAll(".sidebarmenu").forEach((el) => {
            el.classList.toggle("close");
        });
        const dataTheme = document.body.getAttribute("data-sidebartype");
        document.body.setAttribute(
            "data-sidebartype",
            dataTheme === "full" ? "mini-sidebar" : "full"
        );
    };
    const { profileData } = EmployeeData();
    const dispatch = useDispatch();
    // const profileData = useSelector(state => state.profileData);

    // useEffect(() => {
    //     if (!profileData) {
    //         dispatch(fetchProfileData());
    //     }
    //     else { }
    // }, [dispatch, profileData]);

    const userName = profileData?.first_name ?? 'N/A';


    const { setIsAuthenticated } = useAuth();
    const handleLogout = async (e) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out of your account.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, log out',
            cancelButtonText: 'No, cancel',
            reverseButtons: true
        });
        if (result.isConfirmed) {
            dispatch(userLogout(setIsAuthenticated, navigate));
        }
    };

    return (
        <>
            <aside className="left-sidebar with-vertical">
                <div>
                    <div className="brand-logo d-flex align-items-center justify-content-between">
                        <a href="/dashboard" className="text-nowrap logo-img">
                            <img
                                src="./assets/image/Savvyyy.png"
                                className="main_logo"
                                alt="Logo-light"
                            />
                        </a>
                        <a
                            type="button"
                            className="sidebartoggler ms-auto text-decoration-none fs-5 d-block d-xl-none"
                            onClick={handleSidebarToggle}
                        >
                            <i className="ti ti-x"></i>
                        </a>
                    </div>

                    <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                        <ul id="sidebarnav">
                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link ${isActiveRoute("/dashboard") ? "active" : ""}`}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/dashboard");
                                    }}
                                    id="get-url"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <i className="ti ti-home"></i>
                                    </span>
                                    <span className="hide-menu">Dashboard</span>
                                </a>
                            </li>


                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link ${isActiveRoute("/policy") ? "active" : ""}`}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/policy");
                                    }}
                                    id="get-url"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <i className="ti ti-cards"></i>
                                    </span>
                                    <span className="hide-menu">Policy</span>
                                </a>
                            </li>

                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link ${isActiveRoute("/beneficiary") ? "active" : ""}`}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/beneficiary");
                                    }}
                                    id="get-url"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <i className="ti ti-users"></i>
                                    </span>
                                    <span className="hide-menu">Beneficiary</span>
                                </a>
                            </li>

                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link ${isActiveRoute("/e-card") ? "active" : ""}`}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/e-card");
                                    }}
                                    id="get-url"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <i className="ti ti-id-badge-2"></i>
                                    </span>
                                    <span className="hide-menu">E-card</span>
                                </a>
                            </li>


                            <li
                                className={`sidebar-item ${isParentMenuActive("claims") || expandedMenu === "claims"
                                    ? "expanded"
                                    : ""
                                    }`}
                            >
                                <a
                                    className={`sidebar-link has-arrow ${isParentMenuActive("claims") || expandedMenu === "claims"
                                        ? "active"
                                        : ""
                                        }`}
                                    href="#"
                                    aria-expanded={expandedMenu === "claims"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMenu("claims");
                                    }}
                                >
                                    <span className="d-flex">
                                        <i className="ti ti-file"></i>
                                    </span>
                                    <span className="hide-menu">Claims</span>
                                </a>
                                <ul
                                    aria-expanded={expandedMenu === "claims"}
                                    className={`collapse first-level ${isParentMenuActive("claims") || expandedMenu === "claims"
                                        ? "show"
                                        : ""
                                        }`}
                                >
                                    <li className="sidebar-item">
                                        <a
                                            className={`sidebar-link ${isActiveRoute("/all-claims") ? "active" : ""}`}
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/all-claims");
                                            }}
                                        >
                                            <div className="round-16 d-flex align-items-center justify-content-center">
                                                <i className="ti ti-circle"></i>
                                            </div>
                                            <span className="hide-menu">All Claims</span>
                                        </a>
                                    </li>
                                    <li className="sidebar-item">
                                        <a
                                            className={`sidebar-link ${isActiveRoute("/new-claim") ? "active" : ""}`}
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/new-claim");
                                            }}
                                        >
                                            <div className="round-16 d-flex align-items-center justify-content-center">
                                                <i className="ti ti-circle"></i>
                                            </div>
                                            <span className="hide-menu">New Claim</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>


                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link ${isActiveRoute("/network-hospital") ? "active" : ""}`}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/network-hospital");
                                    }}
                                    id="get-url"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <i className="ti ti-building-hospital"></i>
                                    </span>
                                    <span className="hide-menu">Network Hospital</span>
                                </a>
                            </li>


                            <li
                                className={`sidebar-item ${isParentMenuActive("wellness") || expandedMenu === "wellness"
                                    ? "expanded"
                                    : ""
                                    }`}
                            >
                                <a
                                    className={`sidebar-link has-arrow ${isParentMenuActive("wellness") || expandedMenu === "wellness"
                                        ? "active"
                                        : ""
                                        }`}
                                    href="#"
                                    aria-expanded={expandedMenu === "wellness"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMenu("wellness");
                                    }}
                                >
                                    <span className="d-flex">
                                        <i className="ti ti-first-aid-kit"></i>
                                    </span>
                                    <span className="hide-menu">Wellness</span>
                                </a>
                                <ul
                                    aria-expanded={expandedMenu === "wellness"}
                                    className={`collapse first-level ${isParentMenuActive("wellness") || expandedMenu === "wellness"
                                        ? "show"
                                        : ""
                                        }`}
                                >
                                    <li className="sidebar-item">
                                        <a
                                            className={`sidebar-link ${isActiveRoute("/book-appointment") ? "active" : ""}`}
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/book-appointment");
                                            }}
                                        >
                                            <div className="round-16 d-flex align-items-center justify-content-center">
                                                <i className="ti ti-circle"></i>
                                            </div>
                                            <span className="hide-menu">Book Appointment</span>
                                        </a>
                                    </li>
                                    <li className="sidebar-item">
                                        <a
                                            className={`sidebar-link ${isActiveRoute("/view-appointment") ? "active" : ""}`}
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/view-appointment");
                                            }}
                                        >
                                            <div className="round-16 d-flex align-items-center justify-content-center">
                                                <i className="ti ti-circle"></i>
                                            </div>
                                            <span className="hide-menu">View Appointment</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>


                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link ${isActiveRoute("/contact-list") ? "active" : ""}`}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/contact-list");
                                    }}
                                    id="get-url"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <i className="ti ti-address-book"></i>
                                    </span>
                                    <span className="hide-menu">Contact List</span>
                                </a>
                            </li>

                            {/* <li className="sidebar-item">
                                <a
                                    className={`sidebar-link ${isActiveRoute("/claim-process-flow") ? "active" : ""}`}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/claim-process-flow");
                                    }}
                                    id="get-url"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <i className="ti ti-loader-3"></i>
                                    </span>
                                    <span className="hide-menu">Claims Process Flow</span>
                                </a>
                            </li> */}


                            <li
                                className={`sidebar-item ${isParentMenuActive("cpf") || expandedMenu === "cpf"
                                    ? "expanded"
                                    : ""
                                    }`}
                            >
                                <a
                                    className={`sidebar-link has-arrow ${isParentMenuActive("cpf") || expandedMenu === "cpf"
                                        ? "active"
                                        : ""
                                        }`}
                                    href="#"
                                    aria-expanded={expandedMenu === "cpf"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMenu("cpf");
                                    }}
                                >
                                    <span className="d-flex">
                                        <i className="ti ti-loader-3"></i>
                                    </span>
                                    <span className="hide-menu">Claims Process Flow</span>
                                </a>
                                <ul
                                    aria-expanded={expandedMenu === "cpf"}
                                    className={`collapse first-level ${isParentMenuActive("cpf") || expandedMenu === "cpf"
                                        ? "show"
                                        : ""
                                        }`}
                                >
                                    <li className="sidebar-item">
                                        <a
                                            className={`sidebar-link ${isActiveRoute("/cashless-flow") ? "active" : ""}`}
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/cashless-flow");
                                            }}
                                        >
                                            <div className="round-16 d-flex align-items-center justify-content-center">
                                                <i className="ti ti-circle"></i>
                                            </div>
                                            <span className="hide-menu">Cashless Process</span>
                                        </a>
                                    </li>
                                    <li className="sidebar-item">
                                        <a
                                            className={`sidebar-link ${isActiveRoute("/non-cashless-flow") ? "active" : ""}`}
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate("/non-cashless-flow");
                                            }}
                                        >
                                            <div className="round-16 d-flex align-items-center justify-content-center">
                                                <i className="ti ti-circle"></i>
                                            </div>
                                            <span className="hide-menu">Non Cashless Process</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </nav>
                    <div className="fixed-profile p-3 mx-4 mb-2 bg-secondary-subtle rounded mt-3">
                        <div className="hstack gap-3">
                            <div className="john-img">
                                <img
                                    src="./assets/image/profile_image/user-1.jpg"
                                    className="rounded-circle"
                                    width="40"
                                    height="40"
                                    alt="modernize-img"
                                />
                            </div>
                            <div className="john-title">
                                <h6 className="mb-0 fs-4 fw-semibold">{userName}</h6>
                                {/* <span className="fs-2">Designer</span> */}
                            </div>
                            <a
                                href="#"
                                onClick={handleLogout}
                                className="border-0 bg-transparent text-primary ms-auto"
                                tabIndex="0"
                                type="button"
                                aria-label="logout"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-title="logout"
                            >
                                <i className="ti ti-power fs-6"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
};
