import React from 'react';

export const LoadingBtn = () => {
    return (
        <div className="loading-container">
            <span className='loading-dots'>...</span>
        </div>
    );
};

