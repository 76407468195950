import React, { useState, useEffect } from "react";
import { SideBar } from "../../components/SideNavigation/SideNavigation";
import { Header } from "../../components/Header/Header";
import PageHeader from "../../components/Reusable/PageHeader/PageHeader";
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactData } from '../../module/User/UserAction';

export const ContactList = () => {
    const dispatch = useDispatch();
    const contactData = useSelector(state => state.contactData) || [];

    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    const [searchQuery, setSearchQuery] = useState('');
    const [noContactsFound, setNoContactsFound] = useState(false);

    useEffect(() => {
        if (contactData.length === 0) {
            dispatch(fetchContactData());
        } else if (!selectedUser && contactData.length > 0) {
            const firstUser = contactData[0].hospitals[0];
            setSelectedUser(firstUser);
        }
    }, [dispatch, contactData, selectedUser]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleUserClick = (user) => {
        setSelectedUser(user);
        if (isMobile) {
            setShowModal(true);
        }
    };

    const closeModal = () => setShowModal(false);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredDepartments = contactData.filter(department =>
        department.hospitals.some(contact =>
            contact.name.toLowerCase().includes(searchQuery)
        )
    );

    useEffect(() => {
        const hasContacts = filteredDepartments.some(department =>
            department.hospitals.some(contact =>
                contact.name.toLowerCase().includes(searchQuery)
            )
        );
        setNoContactsFound(!hasContacts);
    }, [searchQuery, contactData]);

    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">
                            <PageHeader
                                title="Contact List"
                                pageName="Contact List"
                            />

                            <div className="card overflow-hidden chat-application">
                                <div className="d-flex w-100">
                                    <div className="d-flex w-100">
                                        <div className="min-width-340">
                                            <div className="border-end user-chat-box ">

                                                <div className="px-4 pt-3 pb-6 d-lg-block">
                                                    <form className="position-relative" onSubmit={(e) => e.preventDefault()}>
                                                        <input
                                                            type="text"
                                                            className="form-control search-chat py-2 ps-5"
                                                            id="text-srh"
                                                            placeholder="Search"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                        />
                                                        <i
                                                            className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"
                                                        ></i>
                                                    </form>
                                                </div>

                                                <div className="app-chat">
                                                    {contactData.map((department) => {
                                                        const hasVisibleContacts = department.hospitals.some(contact =>
                                                            contact.name.toLowerCase().includes(searchQuery)
                                                        );

                                                        return hasVisibleContacts ? (
                                                            <div key={department.department}>
                                                                <div style={{ background: '#ecf3fe' }} className="pt-1 pb-1">
                                                                    <span className="hide-menu ms-4">{department.department}</span>
                                                                </div>
                                                                <ul className="chat-users mh-n100 p-0 m-0" data-simplebar>
                                                                    {department.hospitals.map(contact => (
                                                                        <li key={contact.name} style={{ display: contact.name.toLowerCase().includes(searchQuery) ? 'block' : 'none' }}>
                                                                            <a
                                                                                href="#"
                                                                                className={`px-4 py-3 bg-hover-light-black d-flex align-items-center chat-user ${selectedUser === contact ? 'bg-selected-user' : ''
                                                                                    }`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    handleUserClick(contact);
                                                                                }}
                                                                            >
                                                                                <span className="position-relative">
                                                                                    <img
                                                                                        src={contact?.profile_image || 'https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/profile/user-3.jpg'}
                                                                                        alt={contact.name}
                                                                                        width="40"
                                                                                        height="40"
                                                                                        className="rounded-circle"
                                                                                    />
                                                                                </span>
                                                                                <div className="ms-6 d-inline-block w-75">
                                                                                    <h6 className="mb-1 fw-semibold chat-title">{contact.name}</h6>
                                                                                    <span className="fs-2 text-body-color d-block">{contact.email}</span>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ) : null;
                                                    })}

                                                    {noContactsFound && (
                                                        <div className="text-center py-3">
                                                            <p className="text-muted">No contacts found</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100">
                                            <div className="chat-container h-100 w-100">
                                                <div className="chat-box-inner-part h-100">
                                                    <div className="chatting-box app-email-chatting-box con-det">
                                                        <div className="p-9 py-3 border-bottom chat-meta-user d-flex align-items-center justify-content-between">
                                                            <h5 className="text-dark mb-0 fs-5">Contact Details</h5>
                                                        </div>
                                                        <div className="position-relative overflow-hidden">
                                                            <div className="position-relative">
                                                                <div className="chat-box email-box mh-n100 p-9">
                                                                    <div className="chat-list chat active-chat">
                                                                        {selectedUser ? (
                                                                            <>
                                                                                <div className="hstack align-items-start mb-7 pb-1 align-items-center justify-content-between">
                                                                                    <div className="d-flex align-items-center gap-3">
                                                                                        <img
                                                                                            src={selectedUser?.profile_image || 'https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/profile/user-3.jpg'}
                                                                                            alt={selectedUser.name}
                                                                                            width="72"
                                                                                            height="72"
                                                                                            className="rounded-circle"
                                                                                        />
                                                                                        <div>
                                                                                            <h6 className="fs-4 mb-0">{selectedUser.name}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row d-flex flex-wrap">
                                                                                    <div className="col-4 mb-7">
                                                                                        <p className="mb-1 fs-2">Phone number</p>
                                                                                        <h6 className="fw-semibold mb-0">{selectedUser.mobile_number || 'N/A'}</h6>
                                                                                    </div>
                                                                                    <div className="col-8 mb-7">
                                                                                        <p className="mb-1 fs-2">Email address</p>
                                                                                        <h6 className="fw-semibold mb-0">{selectedUser.email || 'N/A'}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="border-bottom pb-7 mb-4">
                                                                                    <p className="mb-2 fs-2">Notes</p>
                                                                                    <p className="mb-3 text-dark">{selectedUser.note || 'No notes available'}</p>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <p className="text-center">Select a contact to view details</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && isMobile && selectedUser && (
                <div className="custom-modal2 d-flex justify-content-center align-items-center">
                    <div className="modal-content2 bg-white p-4 rounded shadow-sm position-relative">
                        <button className="btn-close position-absolute top-0 end-0 p-3" onClick={closeModal}></button>
                        <div className="d-flex align-items-center mb-4">
                            <img
                                src={selectedUser?.profile_image || 'https://bootstrapdemos.adminmart.com/modernize/dist/assets/images/profile/user-3.jpg'}
                                alt={selectedUser.name}
                                width="60"
                                height="60"
                                className="rounded-circle me-3"
                            />
                            <h5 className="modal-title">{selectedUser.name}</h5>
                        </div>
                        <div className="text-start">
                            <p ><strong>Email:</strong> {selectedUser.email || 'N/A'}</p>
                            <p ><strong>Phone:</strong> {selectedUser.mobile_number || 'N/A'}</p>
                            <p ><strong>Notes:</strong> {selectedUser.note || 'No notes available'}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
