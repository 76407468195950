import React from 'react';
import Swal from 'sweetalert2';

export const Message = async (title = '', description = '', type = '') => {
    return Swal.fire({
        title: title,
        text: description,
        icon: type,
        showCancelButton: false,
        confirmButtonText: 'OK',
    }).then((result) => {
    });
};
