import { USER_LOGIN, USER_LOGOUT, DASHBOARD_DATA, POLICY_DATA, PROFILE_DATA, BENEFICIARY_DATA, ECARD_DATA, HOSPITAL_DATA, APPOINTMENT_DATA, CLAIMS_DATA, BOOK_APPOINTMENT, CONTACT_DATA, COUNTRIES_DATA } from "./UserModules";

const initialState = {
    user: null,
    isAuthenticated: false,
    dashboardData: null,
    policyData: null,
    beneficiaryData: null,
    ecardData: null,
    hospitalData: null,
    appointmentData: null,
    claimsData: null,
    bookAppointment: null,
    contactData: null,
    countriesData: null,
    profileData: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN: {
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
            };
        }
        case USER_LOGOUT: {
            return {
                ...state,
                user: null,
                dashboardData: null,
                policyData: null,
                beneficiaryData: null,
                ecardData: null,
                hospitalData: null,
                appointmentData: null,
                claimsData: null,
                contactData: null,
                countriesData: null,
                profileData: null,
                isAuthenticated: false,
            };
        }

        case DASHBOARD_DATA: {
            return {
                ...state,
                dashboardData: action.payload,
            };
        }
        case POLICY_DATA: {
            return {
                ...state,
                policyData: action.payload,
            };
        }
        case BENEFICIARY_DATA: {
            return {
                ...state,
                beneficiaryData: action.payload,
            };
        }
        case ECARD_DATA: {
            return {
                ...state,
                ecardData: action.payload,
            };
        }
        case HOSPITAL_DATA: {
            return {
                ...state,
                hospitalData: action.payload,
            };
        }
        case APPOINTMENT_DATA: {
            return {
                ...state,
                appointmentData: action.payload,
            };
        }
        case CLAIMS_DATA: {
            return {
                ...state,
                claimsData: action.payload,
            };
        }
        case BOOK_APPOINTMENT: {
            return {
                ...state,
                bookAppointment: action.payload,
            };
        }
        case CONTACT_DATA: {
            return {
                ...state,
                contactData: action.payload,
            };
        }
        case COUNTRIES_DATA: {
            return {
                ...state,
                countriesData: action.payload,
            };
        }
        case PROFILE_DATA: {
            return {
                ...state,
                profileData: action.payload,
            };
        }
        default:
            return state;
    }
};

export default userReducer;