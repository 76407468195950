import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../../components/SideNavigation/SideNavigation";
import { Header } from "../../../components/Header/Header";
import { Message } from "../../../components/AlertMessage/Message";
import PageHeader from "../../../components/Reusable/PageHeader/PageHeader";
import { LoadingBtn } from "../../../components//Action/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointmentData, fetchBeneficiaryData, bookAppointment, fetchCountries, fetchStates, fetchCity, fetchHospitalData } from '../../../module/User/UserAction';

export const BookAppointment = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const appointmentData = useSelector(state => state.appointmentData);
    const beneficiaryData = useSelector(state => state.beneficiaryData);
    const countriesData = useSelector(state => state.countriesData);
    const hospitalData = useSelector(state => state.hospitalData);

    const empId = appointmentData?.[0]?.employee_id;
    const countriesId = "101";
    const countryId = countriesId;

    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [cityOptions, setCityOptions] = useState([]);
    const [selectedDiagnosticCenter, setSelectedDiagnosticCenter] = useState(null);
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [preferredDate1, setPreferredDate1] = useState("");
    const [preferredDate2, setPreferredDate2] = useState("");

    useEffect(() => {
        if (!appointmentData && !countriesData && !hospitalData && !beneficiaryData) {
            dispatch(fetchAppointmentData());
            dispatch(fetchCountries());
            dispatch(fetchBeneficiaryData());
            dispatch(fetchHospitalData());
        } else { }
    }, [dispatch, appointmentData, countriesData, hospitalData, beneficiaryData]);

    const patientOptions = beneficiaryData?.map(beneficiary => ({
        value: beneficiary?._id,
        label: beneficiary?.beneficiary_name || 'N/A',
    })) || [];

    const diagOptions = selectedCity
        ? hospitalData
            .filter(hospital => hospital.city.name === selectedCity.label)
            .map(hospital => ({
                value: hospital._id,
                label: hospital.name || 'N/A',
            }))
        : [];

    const handleFetchStates = async () => {
        try {
            const fetchedStates = await dispatch(fetchStates(countryId, {}));

            const options = fetchedStates.data.map(state => ({
                value: state._id,
                label: state.name || 'N/A',
                statesId: state.id,
            }));
            setStateOptions(options);
        } catch (error) { }
    };

    const handleFetchCity = async (stateId) => {
        try {
            const fetchedCity = await dispatch(fetchCity(stateId, {}));

            const options = fetchedCity.data.map(city => ({
                value: city._id,
                label: city.name || 'N/A',
            }));
            setCityOptions(options);
        } catch (error) { }
    };


    const [selectErrors, setSelectErrors] = useState({
        patient: false,
        state: false,
        city: false,
        diagnosticCenter: false,
        mobileNumber: false,
        email: false,
        preferredDate1: false,
        preferredDate2: false
    });

    const validateMobileNumber = (value) => /^\d{10}$/.test(value);
    const validateEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

    const handleMobileNumberChange = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        if (value.length <= 10) {
            setMobileNumber(value);
            if (validateMobileNumber(value)) {
                setSelectErrors(prevErrors => ({ ...prevErrors, mobileNumber: false }));
            } else {
                setSelectErrors(prevErrors => ({ ...prevErrors, mobileNumber: true }));
            }
        }
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
        if (validateEmail(value)) {
            setSelectErrors((prevErrors) => ({ ...prevErrors, email: false }));
        } else {
            setSelectErrors((prevErrors) => ({ ...prevErrors, email: true }));
        }
    };

    const handlePreferredDateChange = (value, dateField) => {
        if (dateField === 'preferredDate1') {
            setPreferredDate1(value);
        } else if (dateField === 'preferredDate2') {
            setPreferredDate2(value);
        }
        if (value) {
            setSelectErrors((prevErrors) => ({ ...prevErrors, [dateField]: false }));
        } else {
            setSelectErrors((prevErrors) => ({ ...prevErrors, [dateField]: true }));
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const errors = {
            patient: !selectedPatient,
            state: !selectedState,
            city: !selectedCity,
            diagnosticCenter: !selectedDiagnosticCenter,
            mobileNumber: !validateMobileNumber(mobileNumber),
            email: !validateEmail(email),
            preferredDate1: !preferredDate1,
            preferredDate2: !preferredDate2
        };

        setSelectErrors(errors);

        if (Object.values(errors).some(error => error)) {
            setValidated(true);
            return;
        }

        setValidated(true);
        setIsLoading(true);

        try {
            await dispatch(bookAppointment({
                employee_id: empId,
                beneficiary_id: selectedPatient.value,
                preferred_first_date: preferredDate1,
                preferred_second_date: preferredDate2,
                state: selectedState.value,
                city: selectedCity.value,
                hospital_id: selectedDiagnosticCenter.value,
                mobile_no: mobileNumber,
                email: email
            }));

            await Message("Appointment submitted successfully!", "", "success");
            dispatch(fetchAppointmentData());
            navigate('/view-appointment');
        } catch (error) { }
        finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <div id="main-wrapper">
                <SideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="body-wrapper">
                        <div className="container-fluid">

                            <PageHeader
                                title="Book Appointment"
                                pageName="Book Appointment"
                            />

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form noValidate onSubmit={handleSubmit}>
                                                <h4 className="card-title mb-3">Appointment details</h4>
                                                <hr />
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="patient-selector2">Appointment For</label>
                                                            <Select
                                                                id="patient-selector2"
                                                                options={patientOptions}
                                                                placeholder="Select Patient Name"
                                                                value={selectedPatient}
                                                                onChange={(selectedOption) => {
                                                                    setSelectedPatient(selectedOption);
                                                                    setSelectErrors(prev => ({ ...prev, patient: !selectedOption }));
                                                                }}
                                                                classNamePrefix="select"
                                                            />
                                                            {selectErrors.patient && <div className="invalid-feedback d-block">Please select a patient name.</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="preferred-date-1">
                                                                Preferred First Date
                                                            </label>
                                                            <input
                                                                type="date"
                                                                id="preferred-date-1"
                                                                className={`form-control ${selectErrors.preferredDate1 ? 'is-invalid' : ''}`}
                                                                value={preferredDate1}
                                                                onChange={(e) => handlePreferredDateChange(e.target.value, 'preferredDate1')}
                                                                onBlur={() => {
                                                                    if (!preferredDate1) {
                                                                        setSelectErrors(prev => ({ ...prev, preferredDate1: true }));
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                            {selectErrors.preferredDate1 && <div className="invalid-feedback">Please select a date</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="preferred-date-2">
                                                                Preferred Second
                                                            </label>
                                                            <input
                                                                type="date"
                                                                id="preferred-date-2"
                                                                className={`form-control ${selectErrors.preferredDate2 ? 'is-invalid' : ''}`}
                                                                value={preferredDate2}
                                                                onChange={(e) => handlePreferredDateChange(e.target.value, 'preferredDate2')}
                                                                onBlur={() => {
                                                                    if (!preferredDate2) {
                                                                        setSelectErrors(prev => ({ ...prev, preferredDate2: true }));
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                            {selectErrors.preferredDate2 && <div className="invalid-feedback">Please select a date</div>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="card-title mb-3">Medical Center Details</h4>
                                                <hr />
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="Select-state">Select State</label>
                                                            <Select
                                                                id="Select-state"
                                                                options={stateOptions}
                                                                placeholder="Select State"
                                                                value={selectedState}
                                                                onChange={(selectedOption) => {
                                                                    setSelectedState(selectedOption);
                                                                    setSelectedCity(null);
                                                                    setSelectedDiagnosticCenter(null);
                                                                    setSelectErrors(prev => ({ ...prev, state: !selectedOption }));
                                                                    if (selectedOption) {
                                                                        handleFetchCity(selectedOption.statesId);
                                                                    } else {
                                                                        setCityOptions([]);
                                                                    }
                                                                }}
                                                                onFocus={handleFetchStates}
                                                                classNamePrefix="select"
                                                            />
                                                            {selectErrors.state && <div className="invalid-feedback d-block">Please select a state.</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="Select-city">Select City</label>
                                                            <Select
                                                                id="Select-city"
                                                                options={cityOptions}
                                                                placeholder="Select City"
                                                                value={selectedCity}
                                                                onChange={(selectedOption) => {
                                                                    setSelectedCity(selectedOption);
                                                                    setSelectErrors(prev => ({ ...prev, city: !selectedOption }));
                                                                    setSelectedDiagnosticCenter(null);
                                                                }}
                                                                classNamePrefix="select"
                                                            />
                                                            {selectErrors.city && <div className="invalid-feedback d-block">Please select a city.</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="Select-Diagnostic-Center">Select Diagnostic Center</label>
                                                            <Select
                                                                id="Select-Diagnostic-Center"
                                                                options={diagOptions}
                                                                placeholder="Select Diagnostic Center"
                                                                value={selectedDiagnosticCenter}
                                                                onChange={(selectedOption) => {
                                                                    setSelectedDiagnosticCenter(selectedOption);
                                                                    setSelectErrors(prev => ({ ...prev, diagnosticCenter: !selectedOption }));
                                                                }}
                                                                classNamePrefix="select"
                                                            />
                                                            {selectErrors.diagnosticCenter && <div className="invalid-feedback d-block">Please select a diagnostic center.</div>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className="card-title mb-3">Contact Details</h4>
                                                <hr />
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="mobileNumber">Mobile Number</label>
                                                            <input
                                                                type="text"
                                                                id="mobileNumber"
                                                                placeholder="Enter Mobile Number"
                                                                className={`form-control ${selectErrors.mobileNumber ? 'is-invalid' : ''}`}
                                                                value={mobileNumber}
                                                                onChange={handleMobileNumberChange}
                                                                onBlur={() => {
                                                                    if (!validateMobileNumber(mobileNumber)) {
                                                                        setSelectErrors(prev => ({ ...prev, mobileNumber: true }));
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                            {selectErrors.mobileNumber && <div className="invalid-feedback">Please enter a valid 10-digit mobile number.</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="email">Email</label>
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                className={`form-control ${selectErrors.email ? 'is-invalid' : ''}`}
                                                                value={email}
                                                                placeholder="Enter Email"
                                                                onChange={handleEmailChange}
                                                                onBlur={() => {
                                                                    if (!validateEmail(email)) {
                                                                        setSelectErrors(prev => ({ ...prev, email: true }));
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                            {selectErrors.email && <div className="invalid-feedback">Please enter a valid email address.</div>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <div className="row">
                                                        <div className="col text-end d-flex justify-content-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary hstack gap-6"
                                                                disabled={isLoading}
                                                            >
                                                                {isLoading ? <LoadingBtn /> : <><i className="ti ti-send fs-4"></i> Submit</>}

                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
