import React, { useState, useEffect } from "react";
import { SideBar } from "../../components/SideNavigation/SideNavigation";
import { Header } from "../../components/Header/Header";
import PageHeader from "../../components/Reusable/PageHeader/PageHeader";
import ReusableTable from "../../components/Reusable/ReuseTable/ReuseTable";
import ReusableForm from "../../components/Reusable/ReuseFilter/ReuseFilter";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBeneficiaryData } from '../../module/User/UserAction';
import { formatDate } from '../../utility/CommonConstant';
import { EmployeeData } from '../../utility/EmployeeData';
import { EditBeneficiary } from "./EditBeneficiary";

export const Beneficiary = () => {
    // const dispatch = useDispatch();
    // const beneficiaryData = useSelector(state => state.beneficiaryData);
    const { beneficiaryData } = EmployeeData();

    const [filteredData, setFilteredData] = useState(beneficiaryData || []);
    const [filterValues, setFilterValues] = useState({
        beneficiaryName: '',
        mediAssistId: '',
        age: '',
        gender: ''
    });
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

    useEffect(() => {
        if (beneficiaryData) {
            setFilteredData(beneficiaryData);
        } else { }
    }, [beneficiaryData]);

    const handleReset = () => {
        setFilteredData(beneficiaryData);
    };

    const handleFilter = () => {
        const filtered = beneficiaryData.filter(item => {
            return (
                (!filterValues.beneficiaryName || item.beneficiary_name?.toLowerCase().includes(filterValues.beneficiaryName.toLowerCase())) &&
                (!filterValues.mediAssistId || item.medi_assist_id?.toString().includes(filterValues.mediAssistId)) &&
                (!filterValues.age || item.age?.toString() === filterValues.age) &&
                (!filterValues.gender || item.gender?.toLowerCase() === filterValues.gender.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

    // useEffect(() => {
    //     setFilteredData(beneficiaryData);
    //     if (!beneficiaryData) {
    //         dispatch(fetchBeneficiaryData());
    //     } else {
    //         setFilteredData(beneficiaryData);
    //     }
    // }, [dispatch, beneficiaryData]);

    const beneficiaryHeaders = [
        'Beneficiary Name', 'Medi Assist Id', 'Dob', 'Age', 'Relation', 'Gender', 'Action'
    ];

    const handleEditClick = (beneficiary) => {
        setSelectedBeneficiary(beneficiary);
        document.querySelector('#beneficiary .btn-close').click();
    };

    const beneficiaryTableData = filteredData?.map(item => ({
        _id: item?._id,
        salse_policy_id: item?.salse_policy_id,
        employee_id: item?.employee_id,
        rowData: [
            item?.beneficiary_name ?? 'N/A',
            item?.medi_assist_id ?? '0',
            formatDate(item?.dob),
            item?.age ?? '0',
            item?.relation ?? 'N/A',
            item?.gender ?? 'N/A',
            <i
                className="ti ti-edit me-3 cursor-pointer"
                id="openAddContactModal"
                data-bs-toggle="modal" data-bs-target="#beneficiary"
                onClick={() => handleEditClick(item)}
            ></i>
        ]
    })) || [];

    const beneficiaryFields = [
        { id: 'beneficiaryName', name: 'beneficiaryName', label: 'Beneficiary Name', type: 'text', placeholder: 'Enter Beneficiary Name' },
        { id: 'mediAssistId', name: 'mediAssistId', label: 'Medi Assist Id', type: 'text', placeholder: 'Enter Medi Assist Id' },
        { id: 'age', name: 'age', label: 'Age', type: 'number', placeholder: 'Enter Age' },
        {
            id: 'gender',
            name: 'gender',
            label: 'Gender',
            type: 'select',
            placeholder: 'Select Gender',
            options: [
                { value: '', label: 'All' },
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' }
            ]
        },
    ];

    return (
        <div id="main-wrapper">
            <SideBar />
            <div className="page-wrapper">
                <Header />
                <div className="body-wrapper">
                    <div className="container-fluid">
                        <PageHeader title="Beneficiary List" pageName="Beneficiary List" />
                        <ReusableForm
                            fields={beneficiaryFields}
                            onReset={handleReset}
                            onSubmit={handleFilter}
                            onChange={(e) => setFilterValues({
                                ...filterValues,
                                [e.target.name]: e.target.value
                            })}
                        />
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="card-title">Beneficiary List</h4>
                                </div>
                                <div className="table-responsive">
                                    <ReusableTable
                                        headers={beneficiaryHeaders}
                                        data={beneficiaryTableData.map(item => item.rowData)}
                                        noDataText="No Data Found"
                                        rowIds={beneficiaryTableData.map(item => item._id)}
                                    />
                                </div>
                            </div>
                        </div>

                        <EditBeneficiary selectedBeneficiary={selectedBeneficiary} />
                    </div>
                </div>
            </div>
        </div>
    );
};
