import React from 'react';

const ReusableTable2 = ({ headers, data, noDataText }) => {
    return (
        <table className="table table-striped table-bordered display text-nowrap">
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody className="text-center">
                {data && data.length > 0 ? (
                    data.map((row, rowIndex) => (
                        <tr key={rowIndex} className="text-start">
                            {row.map((cell, cellIndex) => (
                                <td
                                    key={cellIndex}
                                    className={cellIndex === row.length - 1 ? 'text-start' : ''}
                                >
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={headers.length} className="py-3">{noDataText}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default ReusableTable2;
